import { RouteObject } from 'react-router-dom';
import Layout from '@/pages/layout';

import Main from '@/pages/main';
import Terms from '@/pages/terms';
import Contact from '@/pages/contact';
import FAQ from '@/pages/faq';

import Login from '@/pages/login';

import DCheckIn from '@/pages/in/CheckIn';
import DCheckOut from '@/pages/out/CheckOut';
import DDashboard from '@/pages/dallas/dashboard';
import Dallas from '@/pages/dallas';
import DallasAfter from '@/pages/dallas-after';
import DallasUpgrade from '@/pages/dallas-upgrade';
import PurchaseThanks from '@/pages/purchaseThanks';

// import VMLDashboard from '@/pages/vml';

import Pre from '@/pages/pre';
import Post from '@/pages/post';

import NotFound from '@/pages/NotFound';

export default [
  {
    path: '/',
    element: <Layout />,
    children: [{ index: true, element: <Main /> }],
  },
  {
    path: '/contact',
    element: <Layout />,
    children: [{ index: true, element: <Contact /> }],
  },
  {
    path: '/faq',
    element: <Layout />,
    children: [{ index: true, element: <FAQ /> }],
  },
  {
    path: '/terms',
    element: <Layout />,
    children: [{ index: true, element: <Terms /> }],
  },
  {
    path: '/Dallas',
    element: <Layout />,
    children: [{ index: true, element: <Dallas /> }],
  },
  {
    path: '/Dallas-after',
    element: <Layout />,
    children: [{ index: true, element: <DallasAfter /> }],
  },
  {
    path: '/Dallas-upgrade',
    element: <Layout />,
    children: [{ index: true, element: <DallasUpgrade /> }],
  },
  {
    path: '/Dallas/dashboard',
    element: <DDashboard />,
  },
  {
    path: '/Dallas/dashboard/login',
    element: <Login />,
  },
  {
    path: '/Dallas/in',
    element: <DCheckIn />,
  },
  {
    path: '/Dallas/in/login',
    element: <Login />,
  },
  {
    path: '/Dallas/out',
    element: <DCheckOut />,
  },
  {
    path: '/Dallas/out/login',
    element: <Login />,
  },
  {
    path: '/purchaseThanks',
    element: <Layout />,
    children: [{ index: true, element: <PurchaseThanks /> }],
  },
  {
    path: '/pre',
    element: <Layout />,
    children: [{ index: true, element: <Pre /> }],
  },
  {
    path: '/post',
    element: <Layout />,
    children: [{ index: true, element: <Post /> }],
  },
  // {
  //   path: '/vml',
  //   element: <Layout />,
  //   children: [{ index: true, element: <VMLDashboard /> }],
  // },
  // {
  //   path: '/vml/login',
  //   element: <Layout />,
  //   children: [{ index: true, element: <Login /> }],
  // },

  {
    path: '/*',
    element: <NotFound />,
  },
] as RouteObject[];
