import { useLanguage } from '@/contexts/LanguageContext';

interface Translation {
  Key: string;
  'Alternate Keys': string;
  English: string;
  French: string;
  Spanish: string;
}

interface Translations {
  [key: string]: Translation;
}

import translations from '../locales/locale.json';
const typedTranslations = translations as Translations;

export const useTranslation = () => {
  const { language } = useLanguage();
  
  const t = (key: string) => {
    const translation = typedTranslations[key];
    if (!translation) return key;
    
    return translation[language === 'en' ? 'English' : language === 'fr' ? 'French' : 'Spanish'];
  };

  return { t };
}; 