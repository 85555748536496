/// <reference types="@types/google.maps" />

import { Model } from 'survey-core';
import * as SurveyCore from 'survey-core';
import { FunctionFactory } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { AxiosError } from 'axios';

import * as Sentry from '@sentry/react';
import styles from './_index.module.css';

import 'survey-core/defaultV2.min.css';
import '@/assets/css/survey-override.css';

import { themeJSON } from '@/assets/survey/FordTheme';
import { initSurvey, prepareForSurvey, prepareSurveyOnQuestionAdded } from "@/helpers/surveyTemplatesAll";
import { initSurveyFord } from '@/helpers/surveyTemplatesFord';

import { CheckboxVOIQuestion } from "@/assets/survey/surveysjs_renderers/CheckboxVOI";
import { RadioGroupRowQuestion } from "@/assets/survey/surveysjs_renderers/RadioButtonButton";
import { EmailTextInput } from "@/assets/survey/surveysjs_renderers/EmailTextInput";
import '@/assets/survey/surveysjs_renderers/CheckboxVOITabs';

import baseSurvey from './PreSurvey.json';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Attendee } from '@/types/attendee';

// Register the custom property BEFORE any other survey-related imports
SurveyCore.Serializer.addProperty("autocompleteaddress", {
  name: "country",
  default: "us",
  category: "general",
  visibleIndex: 3
});

initSurveyFord();
initSurvey();

// Helper function to convert string errors to AxiosError format
const createError = (message: string): AxiosError => {
  return new AxiosError(
    message,
    'ERR_CUSTOM',
    undefined,
    undefined,
    undefined
  );
};

function validateEmail(this: {
  question: {
    value: string;
    setPropertyValue: (key: string, value: string) => void;
    didYouMean?: string;
  };
  survey: any;
  returnResult: (valid?: boolean) => void
}) {
  console.log('[validateEmail]', this.question.value);
  const email = this.question.value;

  this.question.setPropertyValue('didYouMean', "");

  if (!email) {
    this.returnResult();
    return;
  }

  fetch('https://us-central1-latitude-lead-system.cloudfunctions.net/validateEmail', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  }).then(response => {
    response.json().then((res) => {
      const { results } = res;

      let valid = true;
      console.log('this.survey', this.survey);
      console.log('this.question', this.question);
      console.log('validateEmail res', res);

      // bad emails are rejected
      if (results?.valid === false) {
        valid = false;
      }

      // disposable email services are rejected
      if (results?.is_disposable === true) {
        valid = false;
      }

      // reject delivery_confidence below 20
      if (results?.delivery_confidence < 20) {
        valid = false;
      }

      // typos are rejected with correction
      if (results.did_you_mean) {
        valid = true;
        this.question.setPropertyValue('didYouMean', results.did_you_mean);

        console.log('this.question after', this.question.didYouMean);
      }

      this.returnResult(valid);
    });
  }).catch(err => {
    // Convert string error to AxiosError
    const axiosError = createError(err.toString());
    alert(axiosError.message);
  });
}

FunctionFactory.Instance.register("validateEmail", validateEmail, true);

const extractUTM = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const utm: Record<string, string> = {};

  for (const [key, value] of params.entries()) {
    if (["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"].includes(key)) {
      utm[key] = value;
    }
  }

  return utm;
}

SurveyCore.setLicenseKey('NDBhNThlYzYtN2EwMy00ZTgxLWIyNGQtOGFkZWJkM2NlNjI3OzE9MjAyNS0wMS0wNA==');

// Title is now set in PreSurvey.json
const surveyModel = new Model(baseSurvey);
surveyModel.applyTheme(themeJSON);

// Add the CSS classes callback
surveyModel.onUpdateQuestionCssClasses.add((survey, options) => {
  const { question, cssClasses } = options;

  if (question.name === "signature") {
    cssClasses.root += " signatureInput";
  }
  if (question.name === "minorSignature") {
    cssClasses.root += " signatureInput";
  }
});

// Disable cache for loaded choices to ensure we get fresh data every time
if (surveyModel.settings && surveyModel.settings.web) {
  surveyModel.settings.web.cacheLoadedChoices = false;
}

// Register a function to filter models based on make
FunctionFactory.Instance.register("filterModelsByMake", function(this: { question: any }, params: any) {
  const makeValue = params[0];
  const modelValue = params[1];
  
  console.log('filterModelsByMake called with:', { makeValue, modelValue });
  
  if (!makeValue || !modelValue) return true;
  
  // Get the model data from the model's properties
  const modelData = this.question.choices.filter((choice: any) => choice.value === modelValue)[0];
  console.log('modelData:', modelData);
  
  // If we can't find the model data, allow it (this shouldn't happen in normal operation)
  if (!modelData || !modelData.make_id) return true;
  
  // Return true if the model's make_id matches the selected make
  const result = modelData.make_id === makeValue;
  console.log('Filter result:', result);
  return result;
}, true);

// Set up event handlers immediately after creating the survey
prepareForSurvey(surveyModel);
prepareSurveyOnQuestionAdded(null, { survey: surveyModel });

// Get the model question
const modelQuestion = surveyModel.getQuestionByName("vehicleDrivenMostModel");
if (modelQuestion) {
  // Remove the choicesByUrl property
  modelQuestion.choicesByUrl = undefined;
}

// Add event listener to trigger model reload when make is changed
surveyModel.onValueChanged.add((sender: any, options: any) => {
  console.log('onValueChanged:', options.name, options.value);
  
  if (options.name === "vehicleDrivenMostMake") {
    console.log('Make changed to:', options.value);
    const makeId = options.value;
    
    // Clear current model selection
    sender.setValue("vehicleDrivenMostModel", null);
    
    // Find the model question
    const modelQuestion = sender.getQuestionByName("vehicleDrivenMostModel");
    if (modelQuestion) {
      console.log('Found model question:', modelQuestion);
      
      // Clear current choices
      modelQuestion.choices = [];
      
      // Load models for the selected make
      if (makeId) {
        fetch('https://cdn.latitudewebservices.com/data/models.json')
          .then(response => response.json())
          .then(data => {
            console.log('Loaded models data:', data);
            
            // Filter the data by make ID
            const filteredData = data.filter((model: any) => model.make_id === makeId);
            console.log('Filtered models:', filteredData);
            
            // Set the filtered choices
            modelQuestion.choices = filteredData.map((item: any) => ({
              value: item.model_id,
              text: item.model,
              make_id: item.make_id
            }));
          })
          .catch(error => {
            console.error('Error loading models:', error);
          });
      }
    }
  }
});

const PreSurvey = () => {
  const [searchParams] = useSearchParams();
  const attendeeID = searchParams.get('attendeeID');

  const [attendee, setAttendee] = useState<Attendee>();
  const [attendeeLoading, setAttendeeLoading] = useState(true);

  useEffect(() => {
    if (attendeeID) {
      const fetchAttendee = async () => {
        try {
          const response = await fetch(
            `https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}getAttendee-e2jtv773lq-uc.a.run.app`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ attendeeID }),
            },
          );

          if (!response.ok) {
            console.error('Error fetching attendee:', response);
            setAttendeeLoading(false);
            throw new Error('Failed to fetch attendee');
          }

          const attendeeData = await response.json();
          console.log('attendeeData', attendeeData);

          surveyModel.title = `${attendeeData.productName} - ${attendeeData.eventTime}`;

          surveyModel.mergeData({
            surveyType: 'preTD',
            attendeeID,
            eventID: attendeeData.eventID,
            cartID: attendeeData.cartID,
            firstName: attendeeData.firstName,
            lastName: attendeeData.lastName,
            email: attendeeData.email,
            phone: attendeeData.phone,
            fordOwner: attendeeData.fordOwner,
            vin: attendeeData.fordOwner ? attendeeData.fordVIN : undefined,
            _utm: extractUTM(),
          });

          setAttendee(attendeeData as Attendee);
          setAttendeeLoading(false);
        } catch (error) {
          console.error('Error fetching attendee:', error);
          setAttendeeLoading(false);
          throw error;
        }
      };

      fetchAttendee();
    }
  }, [attendeeID]);

  useEffect(() => {
    surveyModel.onComplete.add((sender, options) => {
      sender.setValue('surveyDate', new Date());

      console.log('onComplete', sender.data);

      const data = {
        ...sender.data,
      };

      // save data to firestore carts collection
      fetch(`https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}saveSurvey-e2jtv773lq-uc.a.run.app`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          survey: data,
        }),
      })
        .then(() => {
          options.showDataSavingSuccess();
          const redirect = searchParams.get('redirect');
          if (redirect) {
            window.location.href = redirect;
          }
        })
        .catch(err => {
          console.error(err);
          Sentry.captureException(err, {
            tags: {
              page: 'PreDrive',
              location: 'surveyOnComplete',
              type: 'Survey Save Error',
            },
          });
        });
    });
  }, []);

  return (
    <>
      {attendee ? (
        attendee.waiverComplete ? (
          <>
            <div className="fds-layout-grid__inner">
              <div className="fds-layout-grid__cell--span-12">
                <div className="fmc-billboard towing_billboard fmc-billboard--justify-start fmc-billboard--align-start">
                  <div className="fmc-billboard__image event_billboard_background"></div>
                </div>
              </div>
            </div>
            <div className={styles.lightContent}>
              <div className={styles.contentSection}>
                <h1 className="fds-color__text--tertiary fmc-type--heading4 fmc-mb-6 fmc-mb-5-md">
                  Waiver Already Completed
                </h1>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.lightContent}>
            <div className={styles.contentSection}>
              <Survey model={surveyModel} />
            </div>
          </div>
        )
      ) : !attendeeLoading ? (
        <>
          <div className="fds-layout-grid__inner">
            <div className="fds-layout-grid__cell--span-12">
              <div className="fmc-billboard towing_billboard fmc-billboard--justify-start fmc-billboard--align-start">
                <div className="fmc-billboard__image event_billboard_background"></div>
              </div>
            </div>
          </div>
          <div className={styles.lightContent}>
            <div className={styles.contentSection}>
              <h1 className="fds-color__text--tertiary fmc-type--heading4 fmc-mb-6 fmc-mb-5-md">Invalid ID</h1>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.lightContent}>
          <div className={styles.contentSection}>
            <div>Loading Waiver...</div>
          </div>
        </div>
      )}
    </>
  );
};

export default PreSurvey;
