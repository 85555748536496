import { createContext, useContext, useEffect, useState } from 'react';

interface LanguageContextType {
  language: string;
  setLanguage: (lang: string) => void;
  isCanadian: boolean;
  setIsCanadian: (isCanadian: boolean) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
  const [language, setLanguage] = useState('en');
  const [isCanadian, setIsCanadian] = useState(false);

  // Clear context on unmount
  useEffect(() => {
    return () => {
      setLanguage('en');
      setIsCanadian(false);
    };
  }, []);

  return (
    <LanguageContext.Provider value={{ 
      language, 
      setLanguage, 
      isCanadian, 
      setIsCanadian 
    }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}; 