import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, getDocs, where } from 'firebase/firestore';

import { useAuthState } from 'react-firebase-hooks/auth';
import app from '@/services/firebase';
import auth from '@/services/auth';

import { ButtonGroup, Button } from '@progress/kendo-react-buttons';
import { Model, slk } from 'survey-core';
import { Tabulator } from 'survey-analytics/survey.analytics.tabulator';

import 'survey-analytics/survey.analytics.min.css';
import 'tabulator-tables/dist/css/tabulator.min.css';
import 'survey-analytics/survey.analytics.tabulator.min.css';

import cartJSONRaw from '../BootcampForm.json';
import preSurveyJSONRaw from '../../pre/PreSurvey.json';
import postSurveyJSONRaw from '../../post/PostSurvey.json';

slk('NDBhNThlYzYtN2EwMy00ZTgxLWIyNGQtOGFkZWJkM2NlNjI3OzE9MjAyNS0wNy0xOSwyPTIwMjUtMDctMTksND0yMDI1LTA3LTE5');

function DashboardScreen() {
  const navigate = useNavigate();
  const [whichData, setWhichData] = useState<'attendee' | 'cart' | 'preSurvey' | 'postSurvey' | 'combinedSurveys'>(
    'attendee',
  );

  const [user, userLoading, userError] = useAuthState(auth);
  const [thisSurvey, setThisSurvey] = useState<Model>();
  const [allAnswers, setAllAnswers] = useState<any[]>();
  const [surveyDataTable, setSurveyDataTable] = useState<Tabulator>();

  useEffect(() => {
    console.error(userError);
  }, [userError]);

  const db = getFirestore(app, import.meta.env.VITE_DB_NAME);

  useEffect(() => {
    if (userLoading) return;

    if (!user) {
      navigate('./login');
    }
  }, [userLoading]);

  useEffect(() => {
    if (whichData === 'attendee') {
      // Create a simple survey model for attendee data
      const surveyJSON = {
        pages: [
          {
            elements: [
              {
                type: 'text',
                name: 'id',
                visible: false,
              },
              {
                type: 'text',
                name: 'firstName',
                title: 'First Name',
              },
              {
                type: 'text',
                name: 'lastName',
                title: 'Last Name',
              },
              {
                type: 'text',
                name: 'email',
                title: 'Email',
              },
              {
                type: 'text',
                name: 'phone',
                title: 'Phone',
              },
              {
                type: 'text',
                name: 'type',
                title: 'Type',
              },
              {
                type: 'text',
                name: 'cartID',
                title: 'Cart ID',
              },
              {
                type: 'text',
                name: 'eventID',
                title: 'Event ID',
              },
              {
                type: 'text',
                name: 'productName',
                title: 'Product',
              },
              {
                type: 'text',
                name: 'moduleName',
                title: 'Module',
              },
              {
                type: 'text',
                name: 'eventTime',
                title: 'Event Time',
              },
              {
                type: 'boolean',
                name: 'emailSent',
                title: 'Email Sent',
              },
              {
                type: 'boolean',
                name: 'waiverComplete',
                title: 'Waiver Complete',
              },
              {
                type: 'boolean',
                name: 'fordOwner',
                title: 'Ford Owner',
              },
              {
                type: 'text',
                name: 'fordVIN',
                title: 'Ford VIN',
              },
              {
                type: 'text',
                name: 'fordPassID',
                title: 'FordPass ID',
              },
              {
                type: 'text',
                name: 'howLearn',
                title: 'How Learn',
              },
              {
                type: 'text',
                name: '_checkedIn',
                title: 'Checked In',
              },
              {
                type: 'text',
                name: '_checkedOut',
                title: 'Checked Out',
              },
              {
                type: 'text',
                name: '_claimed',
                title: 'Claimed',
              },
              {
                type: 'text',
                name: '_used',
                title: 'Used',
              },
            ],
          },
        ],
      };

      const survey = new Model(surveyJSON);
      setThisSurvey(survey);

      // Get the attendees data
      const attendeesRef = collection(db, 'attendees');
      const q = query(attendeesRef, where('eventID', '==', 'dallas'));
      getDocs(q).then(querySnapshot => {
        const attendees: any[] = [];

        querySnapshot.forEach(doc => {
          const data = doc.data();
          attendees.push({
            id: doc.id,
            ...data,
            displayName: `${data.firstName} ${data.lastName}`,
          });
        });

        setAllAnswers(attendees);
      });
    } else if (whichData === 'cart') {
      const surveyJSON = JSON.parse(JSON.stringify(cartJSONRaw));
      // add a couple fields to the survey json
      surveyJSON.pages[0].elements.unshift(
        {
          type: 'text',
          name: 'id',
          visible: false,
        },
        {
          type: 'text',
          name: 'cartDate',
          inputType: 'datetime-local',
          visible: false,
        },
      );

      const survey = new Model(surveyJSON);
      setThisSurvey(survey);

      // get the answers
      const answersRef = collection(db, 'carts');
      const q = query(answersRef, where('eventID', '==', 'dallas'));
      getDocs(q).then(querySnapshot => {
        const answers: any[] = [];

        querySnapshot.forEach(doc => {
          const data = doc.data();
          answers.push({
            id: doc.id,
            ...data,
            displayName: data.name,
          });
        });

        setAllAnswers(answers);
      });
    }
    if (whichData === 'preSurvey') {
      const surveyJSON = JSON.parse(JSON.stringify(preSurveyJSONRaw));
      // add a couple fields to the survey json
      surveyJSON.pages[0].elements.unshift(
        {
          type: 'text',
          name: 'id',
          visible: false,
        },
        {
          type: 'text',
          name: 'attendeeID',
          visible: false,
        },
        {
          type: 'text',
          name: 'surveyDate',
          inputType: 'datetime-local',
          visible: false,
        },
      );

      const survey = new Model(surveyJSON);
      setThisSurvey(survey);

      // get the answers
      const answersRef = collection(db, 'surveys');
      const q = query(answersRef, where('eventID', '==', 'dallas'), where('surveyType', '==', 'preTD'));
      getDocs(q).then(querySnapshot => {
        const answers: any[] = [];

        querySnapshot.forEach(doc => {
          const data = doc.data();
          answers.push({
            id: doc.id,
            ...data,
            displayName: data.name,
          });
        });

        setAllAnswers(answers);
      });
    }
    if (whichData === 'postSurvey') {
      const surveyJSON = JSON.parse(JSON.stringify(postSurveyJSONRaw));
      // add a couple fields to the survey json
      surveyJSON.pages[0].elements.unshift(
        {
          type: 'text',
          name: 'id',
          visible: false,
        },
        {
          type: 'text',
          name: 'attendeeID',
          visible: false,
        },
        {
          type: 'text',
          name: 'surveyDate',
          inputType: 'datetime-local',
          visible: false,
        },
      );

      const survey = new Model(surveyJSON);
      setThisSurvey(survey);

      // get the answers
      const answersRef = collection(db, 'surveys');
      const q = query(answersRef, where('eventID', '==', 'dallas'), where('surveyType', '==', 'postTD'));
      getDocs(q).then(querySnapshot => {
        const answers: any[] = [];

        querySnapshot.forEach(doc => {
          const data = doc.data();
          answers.push({
            id: doc.id,
            ...data,
            displayName: data.name,
          });
        });

        setAllAnswers(answers);
      });
    }
    if (whichData === 'combinedSurveys') {
      const preSurveyJSON = JSON.parse(JSON.stringify(preSurveyJSONRaw));
      const postSurveyJSON = JSON.parse(JSON.stringify(postSurveyJSONRaw));

      let surveyJSON = preSurveyJSON;

      // Add all pages from postSurveyJSON to preSurveyJSON
      surveyJSON.pages.push(...postSurveyJSON.pages);

      // Add fields to the survey JSON
      surveyJSON.pages[0].elements.unshift(
        {
          type: 'text',
          name: 'attendeeID',
          visible: false,
        },
        {
          type: 'text',
          name: 'surveyDate',
          inputType: 'datetime-local',
          visible: false,
        },
      );

      const survey = new Model(surveyJSON);
      setThisSurvey(survey);

      // get the answers
      const answersRef = collection(db, 'surveys');
      const pre = query(answersRef, where('eventID', '==', 'dallas'), where('surveyType', '==', 'preTD'));
      const post = query(answersRef, where('eventID', '==', 'dallas'), where('surveyType', '==', 'postTD'));

      getDocs(pre).then(querySnapshot => {
        const answersCombined: any[] = [];
        const answersPre: any[] = [];
        const answersPost: any[] = [];

        console.log('preSurveys', querySnapshot.docs.length);
        for (const doc of querySnapshot.docs) {
          const data = doc.data();
          answersPre.push({
            ...data,
            displayName: data.name,
          });
        }

        getDocs(post).then(querySnapshot => {
          console.log('postSurveys', querySnapshot.docs.length);
          for (const doc of querySnapshot.docs) {
            const data = doc.data();
            answersPost.push(data);
          }

          // combine pre & post
          for (const preSurvey of answersPre) {
            const postSurvey = answersPost.find(post => post.attendeeID === preSurvey.attendeeID);
            if (postSurvey) {
              // combine the two
              answersCombined.push({
                ...preSurvey,
                ...postSurvey,
              });
            } else {
              answersCombined.push(preSurvey);
            }
          }
          setAllAnswers(answersCombined);
        });
      });
    }
  }, [whichData]);

  useEffect(() => {
    if (!thisSurvey || !allAnswers) return;

    const surveyDataTable = new Tabulator(thisSurvey, allAnswers, {
      downloadButtons: ['xlsx', 'csv', 'pdf'],
      useNamesAsTitles: true,
    });

    setSurveyDataTable(surveyDataTable);
  }, [thisSurvey, allAnswers]);

  useEffect(() => {
    if (!surveyDataTable) return;

    surveyDataTable.render('surveyDataTable');
    return () => {
      document.getElementById('surveyDataTable')!.innerHTML = '';
    };
  }, [surveyDataTable]);

  return (
    <div>
      <div className="fmc-mb-4">
        <ButtonGroup className="inline-flex rounded-md shadow-sm">
          <Button
            className={`px-4 py-2 text-sm font-medium border ${
              whichData === 'attendee'
                ? 'bg-blue-600 text-white border-blue-600 hover:bg-blue-700'
                : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
            } rounded-l-lg`}
            themeColor={undefined}
            fillMode="flat"
            onClick={() => setWhichData('attendee')}
          >
            Attendee
          </Button>
          <Button
            className={`px-4 py-2 text-sm font-medium border ${
              whichData === 'preSurvey'
                ? 'bg-blue-600 text-white border-blue-600 hover:bg-blue-700'
                : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
            }`}
            themeColor={undefined}
            fillMode="flat"
            onClick={() => setWhichData('preSurvey')}
          >
            Pre Survey
          </Button>
          <Button
            className={`px-4 py-2 text-sm font-medium border ${
              whichData === 'postSurvey'
                ? 'bg-blue-600 text-white border-blue-600 hover:bg-blue-700'
                : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
            }`}
            themeColor={undefined}
            fillMode="flat"
            onClick={() => setWhichData('postSurvey')}
          >
            Post Survey
          </Button>
          <Button
            className={`px-4 py-2 text-sm font-medium border ${
              whichData === 'combinedSurveys'
                ? 'bg-blue-600 text-white border-blue-600 hover:bg-blue-700'
                : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
            }`}
            themeColor={undefined}
            fillMode="flat"
            onClick={() => setWhichData('combinedSurveys')}
          >
            Combined Surveys
          </Button>
          <Button
            className={`px-4 py-2 text-sm font-medium border ${
              whichData === 'cart'
                ? 'bg-blue-600 text-white border-blue-600 hover:bg-blue-700'
                : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
            } rounded-r-lg`}
            themeColor={undefined}
            fillMode="flat"
            onClick={() => setWhichData('cart')}
          >
            Cart
          </Button>
        </ButtonGroup>
      </div>
      <h1>(Responders: {allAnswers?.length})</h1>

      <div id="surveyDataTable" />
    </div>
  );
}

export default DashboardScreen;
