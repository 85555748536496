import React from 'react';
import { Typography } from '@ford/ford-ui-components';
import styles from './_index.module.css';

const Main = () => {
  return (
    <div className={styles.lightContent}>
      <div className={styles.contentSection}>
        <div className={styles.mainContent}>
          <div className={styles.title}>
            <Typography displayStyle="display-3-large-regular" displayColor="text-onlight-strong">
              Contact Us
            </Typography>
          </div>
          
          <div className={styles.subtitle}>
            <Typography 
              displayStyle="body-1-regular" 
              displayColor="text-onlight-subtle"
            >
              Have a question or need help?
            </Typography>
          </div>
        </div>

        <div className={styles.contactInfo}>
          <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
            FORD TOWING BOOTCAMP
          </Typography>
          <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
            <a href="tel:8883673192" className={styles.contactLink}>(888) 367-3192</a>
          </Typography>
          <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
            <a href="mailto:info@fordtowingbootcamp.com" className={styles.contactLink}>
              info@fordtowingbootcamp.com
            </a>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Main;
