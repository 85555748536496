import { Button } from '@ford/ford-ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLink, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';
import React, { useState } from 'react';
import { analytics, logEvent } from '@/firebase';
import styles from './_index.module.css';

interface SocialShareProps {
  url: string;
  title?: string;
  description?: string;
}

export function SocialShare({ url, title = 'Ford Towing Bootcamp', description = 'Join me at Ford Towing Bootcamp in Dallas!' }: SocialShareProps) {
  const [copied, setCopied] = useState(false);
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);
  const encodedDescription = encodeURIComponent(description);
  
  const tweetText = `${description}`;
  const encodedTweetText = encodeURIComponent(tweetText);
  
  const facebookQuote = `${description}`;
  const encodedFacebookQuote = encodeURIComponent(facebookQuote);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setCopied(true);
      logEvent(analytics, 'share', {
        method: 'copy_link',
        content_type: 'url',
        item_id: url
      });
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy URL:', err);
    }
  };

  const openFacebookShare = () => {
    const width = 550;
    const height = 400;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    logEvent(analytics, 'share', {
      method: 'facebook',
      content_type: 'url',
      item_id: url
    });
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
      'facebook-share-dialog',
      `width=${width},height=${height},left=${left},top=${top}`
    );
  };

  const openTwitterShare = () => {
    logEvent(analytics, 'share', {
      method: 'twitter',
      content_type: 'url',
      item_id: url
    });
    window.open(`https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTweetText}`, '_blank');
  };

  const openEmailShare = () => {
    logEvent(analytics, 'share', {
      method: 'email',
      content_type: 'url',
      item_id: url
    });
    window.open(`mailto:?subject=${encodedTitle}&body=${encodedDescription}%0A%0A${encodedUrl}`);
  };

  return (
    <div className={styles.shareContainer}>
      <div className={styles.shareText}>Invite a guest:</div>
      <div className={styles.shareButtons}>
        <button
          onClick={openFacebookShare}
          className={styles.iconButton}
          aria-label="Share on Facebook"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </button>

        <button
          onClick={openTwitterShare}
          className={styles.iconButton}
          aria-label="Share on Twitter"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </button>

        <button
          onClick={openEmailShare}
          className={styles.iconButton}
          aria-label="Share via Email"
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </button>

        <button
          onClick={copyToClipboard}
          className={`${styles.iconButton} ${copied ? styles.copied : ''}`}
          aria-label={copied ? "Link copied to clipboard!" : "Copy link to clipboard"}
        >
          <FontAwesomeIcon icon={copied ? faCheck : faLink} />
        </button>
      </div>
    </div>
  );
} 