import { ElementFactory, Question, Serializer, settings } from 'survey-core';
import { ReactQuestionFactory, SurveyQuestionElementBase } from 'survey-react-ui';

import { Calendar, CalendarCell } from '@progress/kendo-react-dateinputs';
import moment from 'moment-timezone';
import { CSSProperties, createElement } from 'react';
import { uniq } from 'lodash';
import * as Sentry from '@sentry/react';

import { Button } from '@ford/ford-ui-components';

const CUSTOM_TYPE = 'bookeo';

interface CustomIcons {
  [key: string]: string;
}

(settings.customIcons as CustomIcons)['icon-' + CUSTOM_TYPE] = 'icon-calendar';

class BookeoQuestionModel extends Question {
  constructor(name: string) {
    super(name);
  }

  getType() {
    return CUSTOM_TYPE;
  }

  //Trigger re-rendering
  onAnyValueChanged(name: string, questionName: string) {
    super.onAnyValueChanged(name, questionName);
    if (!!name && name === this.seatsQuestion) {
      console.log('seatsQuestion changed', this.data.getValue(name));
      this.setPropertyValue('seats', this.data.getValue(name));
    }

    if (!!name && name === this.productQuestion) {
      console.log('productQuestion changed', this.data.getValue(name));
      this.setPropertyValue('productId', this.data.getValue(name));
    }
  }

  get seatsQuestion() {
    return this.getPropertyValue('seatsQuestion');
  }

  set seatsQuestion(val) {
    this.setPropertyValue('seatsQuestion', val);
  }

  get seats(): number {
    return !!this.survey && !!this.seatsQuestion
      ? this.data.getValue(this.seatsQuestion)
      : this.getPropertyValue('seats');
  }

  set seats(newValue: number) {
    this.setPropertyValue('seats', newValue);
  }

  get appOrWeb(): string {
    return this.getPropertyValue('appOrWeb');
  }

  set appOrWeb(newValue: string) {
    this.setPropertyValue('appOrWeb', newValue);
  }

  get timeZone(): string {
    return this.getPropertyValue('timeZone');
  }

  set timeZone(newValue: string) {
    this.setPropertyValue('timeZone', newValue);
  }

  get waitlist(): boolean {
    return this.getPropertyValue('waitlist');
  }

  set waitlist(newValue: boolean) {
    this.setPropertyValue('waitlist', newValue);
  }

  get productQuestion() {
    return this.getPropertyValue('productQuestion');
  }

  set productQuestion(val) {
    this.setPropertyValue('productQuestion', val);
  }

  get productId() {
    return !!this.survey && !!this.productQuestion
      ? this.data.getValue(this.productQuestion)
      : this.getPropertyValue('productId');
  }

  set productId(newValue: string) {
    this.setPropertyValue('productId', newValue);
  }

  get bookeoKey(): string {
    return this.getPropertyValue('bookeoKey');
  }

  set bookeoKey(newValue: string) {
    this.setPropertyValue('bookeoKey', newValue);
  }

  get customFieldId(): string {
    return this.getPropertyValue('customFieldId');
  }

  set customFieldId(newValue: string) {
    this.setPropertyValue('customFieldId', newValue);
  }

  get preSelectedSlotId(): string | null {
    return this.getPropertyValue('preSelectedSlotId');
  }

  set preSelectedSlotId(newValue: string | null) {
    this.setPropertyValue('preSelectedSlotId', newValue);
  }
}

export function initBookeoQuestion() {
  ElementFactory.Instance.registerElement(CUSTOM_TYPE, name => {
    return new BookeoQuestionModel(name);
  });

  Serializer.addClass(
    CUSTOM_TYPE,
    [
      {
        name: 'appOrWeb',
        default: 'web',
        choices: ['web', 'app', 'both'],
        category: 'general',
        visibleIndex: 2, // Place after the Name and Title
      },
      {
        name: 'bookeoKey',
        category: 'general',
        visibleIndex: 3,
      },
      {
        name: 'customFieldId',
        category: 'general',
        visibleIndex: 3,
      },
      {
        name: 'timeZone',
        category: 'general',
        visibleIndex: 3,
      },
      {
        name: 'waitlist',
        category: 'general',
        visibleIndex: 3,
      },
      {
        name: 'productQuestion',
        type: 'question_selectbase',
        category: 'general',
        visibleIndex: 4,
      },
      {
        name: 'productId',
        category: 'general',
        visibleIndex: 4,
      },
      {
        name: 'seatsQuestion',
        type: 'question_selectbase',
        category: 'general',
        visibleIndex: 4,
      },
      {
        name: 'seats',
        category: 'general',
        visibleIndex: 4,
      },
      {
        name: 'preSelectedSlotId',
        category: 'general',
        visibleIndex: 5,
      },
    ],
    function () {
      return new BookeoQuestionModel('');
    },
    'question',
  );

  ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, props => {
    return createElement(SurveyBookeoQuestion, props);
  });
}

export class SurveyBookeoQuestion extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      value: this.question.value,
      today: moment.tz(this.timeZone),
      startTime: moment().toISOString(),
      endTime: moment('2025-03-16').tz(this.timeZone).endOf('day').toISOString(),
      selectedDate: null,
      availableDays: [],
      availableSlots: [],
      availabilityMessage: '',
      daySlots: [],
      slot: null,
      holdId: '',
      holdError: '',
      hasAttemptedPreSelection: false,
    };

    this.getAvailability();
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  get seats() {
    return this.question.seats;
  }

  set seats(newValue: number) {
    this.question.seats = newValue;
    this.getAvailability();
  }

  get appOrWeb() {
    return this.question.appOrWeb;
  }

  get timeZone() {
    return this.question.timeZone;
  }

  get waitlist() {
    return this.question.waitlist;
  }

  get productId() {
    return this.question.productId;
  }

  set productId(newValue: string) {
    this.question.productId = newValue;
    this.getAvailability();
  }

  get bookeoKey() {
    return this.question.bookeoKey;
  }

  set bookeoKey(newValue: string) {
    this.question.bookeoKey = newValue;
    this.getAvailability();
  }

  get customFieldId() {
    return this.question.customFieldId;
  }

  get preSelectedSlotId() {
    return this.question.preSelectedSlotId;
  }

  async getAvailability() {
    console.log('******getAvailability******');
    if (!this.question.bookeoKey) {
      return console.error('No Bookeo key provided', this.question.appOrWeb);
    }

    if (!this.question.productId) {
      return console.error('No product ID provided');
    }

    this.setState({ isLoading: true });

    fetch(`https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}getBookeoSlotsByProduct-e2jtv773lq-uc.a.run.app`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        productId: this.question.productId,
        startTime: this.state.startTime,
        endTime: this.state.endTime,
        seats: this.question.seats,
        bookeoKey: this.question.bookeoKey,
      }),
    })
      .then(response => {
        this.setState({ isLoading: false });
        response.json().then(data => {
          if (!response.ok) {
            return console.error('Bookeo API Error', data);
          }

          const daysFound = [];
          for (const slot of data.data) {
            daysFound.push(moment.parseZone(slot.startTime).format('YYYY-MM-DD'));
          }
          
          this.setState({
            availableSlots: data.data,
            availableDays: uniq(daysFound),
            availabilityMessage: '',
          }, () => {
            // After setting state with available slots, attempt pre-selection
            this.attemptPreSelection();
          });
        });
      })
      .catch(err => {
        this.setState({ isLoading: false });
        Sentry.captureException(err, {
          tags: {
            page: 'Dallas Fort Worth',
            location: 'getAvailability',
            type: 'Bookeo API Error',
          },
        });
        console.error(err);
      });
  }

  attemptPreSelection() {
    if (this.state.hasAttemptedPreSelection || !this.preSelectedSlotId) {
      return;
    }

    // Find the slot that matches our pre-selected ID
    const preSelectedSlot = this.state.availableSlots.find(
      (slot: any) => slot.eventId === this.preSelectedSlotId
    );

    if (preSelectedSlot) {
      // Extract date from the slot ID
      const dateMatch = this.preSelectedSlotId.match(/_(\d{4}-\d{2}-\d{2})$/);
      if (dateMatch) {
        const dateStr = dateMatch[1];
        const preSelectedDateObj = moment(dateStr).toDate();
        this.setSelectedDate(preSelectedDateObj);
        this.setSelectedSlot(preSelectedSlot);
      }
    }

    this.setState({ hasAttemptedPreSelection: true });
  }

  setSelectedDate = (date: Date) => {
    this.setState({
      selectedDate: date,
      daySlots: this.state.availableSlots.filter(
        (slot: any) => moment.parseZone(slot.startTime).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'),
      ),
    });
  };

  setSelectedSlot = (slot: any) => {
    this.setState({ slot });
    const slotStartTime = moment.parseZone(slot.startTime);
    const slotEndTime = moment.parseZone(slot.endTime);
    this.question.value = {
      eventId: slot.eventId,
      startTime: slotStartTime.tz(this.timeZone).format('dddd MMM D, h:mm a'),
      endTime: slotEndTime.tz(this.timeZone).format('dddd MMM D, h:mm a'),
      seats: this.question.seats,
      productId: this.question.productId,
      bookeoKey: this.question.bookeoKey,
      customFieldId: this.question.customFieldId,
      slot,
    };

    // reserve slot holdBookeoBooking
    fetch(`https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}holdBookeoBooking-e2jtv773lq-uc.a.run.app`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        seats: this.question.seats,
        eventId: slot.eventId,
        productId: this.question.productId,
        previousHoldId: this.state.holdId,
        bookeoKey: this.question.bookeoKey,
      }),
    })
      .then(response => {
        this.setState({ isLoading: false });
        response.json().then(data => {
          if (!response.ok) {
            return console.error('Bookeo API Error', data);
          }

          this.setState({
            holdId: data.id,
            holdError: '',
          });

          this.question.value = {
            ...this.question.value,
            previousHoldId: data.id,
          };
        });
      })
      .catch(err => {
        this.setState({ isLoading: false });
        Sentry.captureException(err, {
          tags: {
            page: 'Dallas Fort Worth',
            location: 'setSelectedSlot',
            type: 'Bookeo API Error',
          },
        });
        console.error(err);
      });
  };

  renderElement() {
    return (
      <>
        {this.state.availableDays.length ? (
          <div className="booking_container">
            <div className={this.productId}>
              <Calendar
                id={this.question.inputId}
                value={this.state.selectedDate}
                onChange={event => {
                  this.setState({ selectedDate: undefined }, () => {
                    this.setSelectedDate(event.value);
                  });
                }}
                className="w-full max-w-md border rounded-lg shadow-sm bg-white"
                focusedDate={moment(this.state.availableDays ? this.state.availableDays[0] : this.state.today).toDate()}
                min={moment(this.state.availableDays ? this.state.availableDays[0] : this.state.today).toDate()}
                max={moment(
                  this.state.availableDays
                    ? this.state.availableDays[this.state.availableDays.length - 1]
                    : this.state.endTime,
                ).toDate()}
              />
            </div>
            <div className="booking_button_container">
              {this.state.daySlots.map((slot: any) => (
                <Button
                  key={slot.eventId}
                  variant={this.state.slot?.eventId === slot.eventId ? 'filled' : 'outlined'}
                  onClick={() => {
                    this.setSelectedSlot(slot);
                  }}
                >
                  {moment.parseZone(slot.startTime).tz(this.timeZone).format('h:mm a')}
                </Button>
              ))}
            </div>
          </div>
        ) : this.state.isLoading ? (
          <div>Loading...</div>
        ) : (
          <div>No availability</div>
        )}
      </>
    );
  }
}
