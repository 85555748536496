import { Typography } from '@ford/ford-ui-components';
import { SocialShare } from '@/components/SocialShare';
import styles from './_index.module.css';

export function PurchaseThanks() {
  return (
    <div className={styles.lightContent}>
      <div className={styles.contentSection}>
        <div className={styles.mainContent}>
          <br/><br/><br/><br/><br/><br/>
          <h2><Typography 
            displayStyle="display-3-large-regular"
            displayColor="text-onlight-strong"
          >
            Thanks
          </Typography></h2>

          <p><Typography 
            displayStyle="body-1-regular"
            displayColor="text-onlight-strong"
          >
            Your purchase is complete. You will receive a confirmation email soon.
          </Typography></p>

          <p><Typography 
            displayStyle="body-1-regular"
            displayColor="text-onlight-strong"
          >
            We look forward to seeing you at Ford Towing Bootcamp!
          </Typography></p>

          <SocialShare 
            url={window.location.origin}
            title="Ford Towing Bootcamp"
            description="Join me at Ford Towing Bootcamp! Ford Towing Bootcamp includes 3 hours of scheduled expert towing instruction from professional trainers, a Ford F-150 and trailer provided for behind-the-wheel instruction, and complimentary refreshments."
          />
        </div>
      </div>
    </div>
  );
}

export default PurchaseThanks;
