import React from 'react';
import routes from '@/routes/index';
import { useRoutes } from 'react-router-dom';
import { LanguageProvider } from '@/contexts/LanguageContext';
import * as Sentry from '@sentry/react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

Sentry.init({
  dsn: 'https://3fc46dea5dc6261a0c865e748e7ffb31@o4506238718967808.ingest.us.sentry.io/4507345185275904',
  environment: import.meta.env.VITE_ENV || 'production',
  enabled: import.meta.env.VITE_ENV !== 'development', // Disable in development
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = () => {
  const element = useRoutes(routes);

  return <LanguageProvider>{element}</LanguageProvider>;
};

export default App;
