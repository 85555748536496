import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import auth from '@/services/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from 'react-firebase-hooks/firestore';
import db from '@/services/db';
import {
  CollectionReference,
  Query,
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  collection,
  query,
  where,
  orderBy,
  updateDoc,
  Timestamp,
  QueryDocumentSnapshot,
  getDocs,
} from 'firebase/firestore';

import moment from 'moment-timezone';
import { Attendee } from '@/types/attendee';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button, Typography } from '@ford/ford-ui-components';
import styles from './_index.module.css';
import './modal-styles.css'; // Import custom modal styling
// Extend Attendee interface to include document reference and ID
interface CheckAttendee extends Omit<Attendee, 'productName'> {
  id: string;
  ref: DocumentReference<DocumentData>;
  eventDate?: Date;
  moduleName?: string;
  productName?: string;
  howLearn: string;
}

const checkUserConverter: FirestoreDataConverter<CheckAttendee> = {
  toFirestore(checkAttendee: CheckAttendee): DocumentData {
    return {
      cartID: checkAttendee.cartID,
      eventID: checkAttendee.eventID,
      productName: checkAttendee.productName,
      eventTime: checkAttendee.eventTime,
      emailSent: checkAttendee.emailSent,
      waiverComplete: checkAttendee.waiverComplete,
      firstName: checkAttendee.firstName,
      lastName: checkAttendee.lastName,
      email: checkAttendee.email,
      phone: checkAttendee.phone,
      type: checkAttendee.type,
      fordOwner: checkAttendee.fordOwner,
      fordPassID: checkAttendee.fordPassID,
      fordVIN: checkAttendee.fordVIN,
      howLearn: checkAttendee.howLearn,
      _checkedIn: checkAttendee._checkedIn ? Timestamp.fromDate(checkAttendee._checkedIn) : null,
      _checkedOut: checkAttendee._checkedOut ? Timestamp.fromDate(checkAttendee._checkedOut) : null,
      _claimed: checkAttendee._claimed ? Timestamp.fromDate(checkAttendee._claimed) : null,
      _used: checkAttendee._used ? Timestamp.fromDate(checkAttendee._used) : null,
      isDupe: checkAttendee.isDupe,
      eventDate: checkAttendee.eventDate,
      moduleName: checkAttendee.moduleName,
    };
  },
  fromFirestore(snapshot, options): CheckAttendee {
    const data = snapshot.data(options);

    // Convert Firestore timestamp to Date directly
    let eventDate = undefined;
    if (data.eventDate && typeof data.eventDate.toDate === 'function') {
      eventDate = data.eventDate.toDate();
    }

    return {
      id: snapshot.id,
      ref: snapshot.ref,
      cartID: data.cartID,
      eventID: data.eventID,
      productName: data.productName,
      eventTime: data.eventTime,
      emailSent: data.emailSent,
      waiverComplete: data.waiverComplete,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      type: data.type,
      fordOwner: data.fordOwner,
      fordPassID: data.fordPassID,
      fordVIN: data.fordVIN,
      howLearn: data.howLearn,
      _checkedIn: data._checkedIn?.toDate(),
      _checkedOut: data._checkedOut?.toDate(),
      _claimed: data._claimed?.toDate(),
      _used: data._used?.toDate(),
      isDupe: data.isDupe,
      eventDate: eventDate,
      moduleName: data.moduleName,
    };
  },
};

// Module mapping - match the exact product names from the database to ensure filtering works
const moduleOptions = [
  { text: 'All Modules', value: 'all' },
  { text: 'Towing 101 Session', value: 'Towing 101 Session' },
  {
    text: 'Ford Pro Trailer Backup & Pro Trailer Hitch Assist™ Demonstration',
    value: 'Ford Pro Trailer Backup Assist™ & Pro Trailer Hitch Assist™ Demonstration',
  },
  { text: 'Hands-On Trailer Backup Experience', value: 'Hands-On Trailer Backup Experience' },
  { text: 'Ultimate Towing Training Experience', value: 'The Ultimate Towing Training Experience' },
];

interface ConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onUpgrade?: () => void;
  attendee: QueryDocumentSnapshot<CheckAttendee> | null;
  checkInMode: boolean;
}

interface UpgradeQRDialogProps {
  isOpen: boolean;
  onClose: () => void;
  attendee: QueryDocumentSnapshot<CheckAttendee> | null;
}

interface FilterOption {
  text: string;
  value: string;
}

function CheckInScreen() {
  const navigate = useNavigate();
  const { eventId } = useParams<{ eventId: string }>();

  // Get event ID from URL params or default to 'dallas'
  const eventID = eventId || 'dallas';

  const [dialogVisible, setDialogVisible] = useState(false);
  const [upgradeDialogVisible, setUpgradeDialogVisible] = useState(false);
  const [checkInMode, setCheckInMode] = useState(true);
  const [attendeesRef, setAttendeesRef] = useState<CollectionReference>();
  const [attendeesQuery, setAttendeesQuery] = useState<Query>();
  const [selectedAttendee, setSelectedAttendee] = useState<QueryDocumentSnapshot<CheckAttendee> | null>(null);
  const [user, userLoading, userError] = useAuthState(auth);
  const [attendees, attendeesLoading, attendeesError] = useCollection(attendeesQuery);
  const isMounted = useRef(true);

  // Filter state
  const [dateOptions, setDateOptions] = useState<FilterOption[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>('all');
  const [selectedModule, setSelectedModule] = useState<string>('all');
  const [timeOptions, setTimeOptions] = useState<FilterOption[]>([]);
  const [selectedTime, setSelectedTime] = useState<string>('all');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredAttendees, setFilteredAttendees] = useState<QueryDocumentSnapshot<CheckAttendee>[]>([]);
  const [isLoadingFilters, setIsLoadingFilters] = useState(true);

  useEffect(() => {
    console.error(attendeesError);
  }, [attendeesError]);

  useEffect(() => {
    console.error(userError);
  }, [userError]);

  useEffect(() => {
    console.log('attendeesLoading', attendeesLoading);
    if (!attendeesLoading && attendees) {
      processAttendees();
    }
  }, [attendeesLoading, attendees]);

  useEffect(() => {
    if (userLoading) return;

    // see if the user is logged in
    console.log('user', user);

    if (!user) {
      navigate('./login');
    }

    setAttendeesRef(collection(db, 'attendees'));
  }, [userLoading, navigate, user]);

  // Load date options once when component mounts
  useEffect(() => {
    if (!userLoading && attendeesRef) {
      setIsLoadingFilters(true);

      // First create a query to get all attendees for this event to populate filters
      // Don't use complex orderBy clauses for getting date options, they may require indices
      const allAttendeesQuery = query(attendeesRef, where('eventID', '==', eventID)).withConverter(checkUserConverter);

      // Get all attendees for this event to populate date options
      getDocs(allAttendeesQuery)
        .then(snapshot => {
          console.log(`Found ${snapshot.size} total attendees for ${eventID} event`);

          // Process date options from all attendees regardless of check-in status
          const dateOptionsTemp: FilterOption[] = [{ text: 'All Dates', value: 'all' }];
          const datesSet = new Set<string>();

          snapshot.forEach(doc => {
            const attendee = doc.data();
            let attendeeDate: moment.Moment | null = null;

            // Prioritize eventTime as it has the correct time data
            if (attendee.eventTime) {
              attendeeDate = moment(attendee.eventTime, 'dddd MMM DD, hh:mm a').tz('America/Chicago');
            } else if (attendee.eventDate) {
              // Fall back to eventDate only if needed
              attendeeDate = moment.utc(attendee.eventDate).tz('America/Chicago');
            }

            if (attendeeDate && attendeeDate.isValid()) {
              const dateStr = attendeeDate.format('YYYY-MM-DD');
              const displayDate = attendeeDate.format('dddd, MMM D, YYYY');
              if (!datesSet.has(dateStr)) {
                datesSet.add(dateStr);
                dateOptionsTemp.push({ text: displayDate, value: dateStr });
              }
            }
          });

          // Sort dates chronologically (earliest first)
          dateOptionsTemp.sort((a, b) => {
            if (a.value === 'all') return -1;
            if (b.value === 'all') return 1;
            return a.value.localeCompare(b.value); // This already sorts ascending (earliest first)
          });

          setDateOptions(dateOptionsTemp);
          setIsLoadingFilters(false);
        })
        .catch(error => {
          console.error('Error fetching attendees:', error);
          setIsLoadingFilters(false);
        });
    }
  }, [userLoading, attendeesRef, eventID]);

  // Update the query when checkInMode or filters change
  useEffect(() => {
    if (!userLoading && attendeesRef) {
      // Cannot use orderBy with non-equality filter on same field
      // We need a different approach for check-in vs check-out
      if (checkInMode) {
        // For check-in: show users NOT checked in yet
        setAttendeesQuery(
          query(
            attendeesRef,
            where('eventID', '==', eventID),
            where('_checkedIn', '==', null),
            orderBy('eventDate', 'asc'),
            orderBy('productName', 'asc'),
            orderBy('firstName', 'asc'),
            orderBy('lastName', 'asc'),
          ).withConverter(checkUserConverter),
        );
      } else {
        // For check-out: show users checked in but not out
        // Sort by check-in time as requested
        setAttendeesQuery(
          query(
            attendeesRef,
            where('eventID', '==', eventID),
            where('_checkedIn', '!=', null),
            where('_checkedOut', '==', null),
            orderBy('_checkedIn', 'asc'), // Sort by check-in time (earliest first)
            orderBy('productName', 'asc'),
            orderBy('firstName', 'asc'),
            orderBy('lastName', 'asc'),
          ).withConverter(checkUserConverter),
        );
      }
    }
  }, [userLoading, checkInMode, attendeesRef, eventID, selectedDate, selectedModule, selectedTime]);

  // Process attendees function now handles filtering
  // No need for a duplicate loadDates implementation since it's handled in the main useEffect

  // Update time options when date or module changes
  useEffect(() => {
    if (!attendees) return;

    const timeOptionsTemp: FilterOption[] = [{ text: 'All Times', value: 'all' }];
    const timesSet = new Set<string>();

    attendees.docs.forEach(doc => {
      const attendee = doc.data();
      let attendeeDate: moment.Moment | null = null;

      // Prioritize eventTime as it has the correct time data
      if (attendee.eventTime) {
        attendeeDate = moment(attendee.eventTime, 'dddd MMM DD, hh:mm a').tz('America/Chicago');
        console.log('attendeeDate from eventTime', attendee.email, attendeeDate);
      } else if (attendee.eventDate) {
        // Fall back to eventDate only if needed
        attendeeDate = moment.utc(attendee.eventDate).tz('America/Chicago');
        console.log('attendeeDate from eventDate', attendee.email, attendeeDate);
      }

      if (attendeeDate) {
        // Extract hour and minutes for display - ensure in Chicago time
        // Use the hours/minutes from the Chicago timezone, not the UTC time
        let hour = parseInt(attendeeDate.format('H'));
        let minute = parseInt(attendeeDate.format('m'));
        console.log('hour (Chicago)', attendee.email, hour);
        console.log('minute (Chicago)', attendee.email, minute);

        // Only include times for the selected date and module (or all if "all" is selected)
        const dateMatches = selectedDate === 'all' || attendeeDate.format('YYYY-MM-DD') === selectedDate;

        const moduleMatches =
          selectedModule === 'all' || (attendee.productName && attendee.productName === selectedModule);

        if (dateMatches && moduleMatches) {
          // Create time key in Chicago timezone
          const timeStr = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
          // Format for display in AM/PM format
          const displayTime = attendeeDate.format('h:mm A');

          if (!timesSet.has(timeStr)) {
            timesSet.add(timeStr);
            timeOptionsTemp.push({ text: displayTime, value: timeStr });
          }
        }
      }
    });

    // Sort times chronologically
    timeOptionsTemp.sort((a, b) => {
      if (a.value === 'all') return -1;
      if (b.value === 'all') return 1;
      return a.value.localeCompare(b.value);
    });

    setTimeOptions(timeOptionsTemp);
    setSelectedTime('all'); // Reset time selection when date or module changes
  }, [attendees, selectedDate, selectedModule]);

  // Helper function to strip non-digit characters from phone numbers
  const stripPhoneNumber = (phone: string): string => {
    return phone ? phone.replace(/\D/g, '') : '';
  };

  // Filter attendees based on selections - using the more robust date handling from processAttendees
  useEffect(() => {
    if (!attendees) return;

    const searchTermLower = searchTerm.toLowerCase().trim();

    const filtered = attendees.docs.filter(doc => {
      const attendee = doc.data();
      let attendeeDate: moment.Moment | null = null;

      // Prioritize eventTime as it has the correct time data
      if (attendee.eventTime) {
        attendeeDate = moment(attendee.eventTime, 'dddd MMM DD, hh:mm a').tz('America/Chicago');
      } else if (attendee.eventDate) {
        // Fall back to eventDate only if needed
        attendeeDate = moment(attendee.eventDate).tz('America/Chicago');
      }

      // If no valid date, include only if all dates selected
      if (!attendeeDate || !attendeeDate.isValid()) {
        return selectedDate === 'all';
      }

      // Filter by date
      if (selectedDate !== 'all') {
        const attendeeDateStr = attendeeDate.format('YYYY-MM-DD');
        if (attendeeDateStr !== selectedDate) return false;
      }

      // Filter by module - exact match instead of includes
      if (selectedModule !== 'all') {
        const moduleMatches = attendee.productName && attendee.productName === selectedModule;
        if (!moduleMatches) return false;
      }

      // Filter by time
      if (selectedTime !== 'all') {
        // Use the consistent timezone-aware approach to get hour and minute
        let hour = parseInt(attendeeDate.format('H'));
        let minute = parseInt(attendeeDate.format('m'));
        const attendeeTimeStr = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        if (attendeeTimeStr !== selectedTime) return false;
      }

      // Filter by search term if one is provided
      if (searchTermLower) {
        const fullName = `${attendee.firstName || ''} ${attendee.lastName || ''}`.toLowerCase();
        const email = (attendee.email || '').toLowerCase();
        const phone = stripPhoneNumber(attendee.phone || '');
        const searchTermDigits = stripPhoneNumber(searchTermLower);

        // Check if the search term is found in name, email, or phone
        const nameMatch = fullName.includes(searchTermLower);
        const emailMatch = email.includes(searchTermLower);
        const phoneMatch = searchTermDigits.length > 0 && phone.includes(searchTermDigits);

        if (!(nameMatch || emailMatch || phoneMatch)) {
          return false;
        }
      }

      return true;
    });

    console.log(`Filtered down to ${filtered.length} attendees based on selected filters`);
    setFilteredAttendees(filtered as QueryDocumentSnapshot<CheckAttendee>[]);
  }, [attendees, selectedDate, selectedModule, selectedTime, searchTerm]);

  // Helper function to get module name by value
  const getModuleNameByValue = (value: string): string => {
    const module = moduleOptions.find(m => m.value === value);
    return module ? module.text : '';
  };

  // Process attendees to extract data for filters
  const processAttendees = () => {
    if (!attendees) return;

    console.log(`Processing ${attendees.docs.length} attendees for ${eventID} event`);

    // Update time options based on current selections
    const timeOptionsTemp: FilterOption[] = [{ text: 'All Times', value: 'all' }];
    const timesSet = new Set<string>();

    attendees.docs.forEach(doc => {
      const attendee = doc.data();
      let attendeeDate: moment.Moment | null = null;

      // Prioritize eventTime as it has the correct time data
      if (attendee.eventTime) {
        attendeeDate = moment(attendee.eventTime, 'dddd MMM DD, hh:mm a').tz('America/Chicago');
      } else if (attendee.eventDate) {
        // Fall back to eventDate only if needed
        attendeeDate = moment.utc(attendee.eventDate).tz('America/Chicago');
      }

      if (attendeeDate && attendeeDate.isValid()) {
        // Only include times for the selected date and module
        const dateMatches = selectedDate === 'all' || attendeeDate.format('YYYY-MM-DD') === selectedDate;

        const moduleMatches =
          selectedModule === 'all' ||
          (attendee.productName && attendee.productName.includes(getModuleNameByValue(selectedModule)));

        if (dateMatches && moduleMatches) {
          // Get hour and minute in Chicago timezone to create the time value
          let hour = parseInt(attendeeDate.format('H'));
          let minute = parseInt(attendeeDate.format('m'));
          const timeStr = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
          const displayTime = attendeeDate.format('h:mm A');
          if (!timesSet.has(timeStr)) {
            timesSet.add(timeStr);
            timeOptionsTemp.push({ text: displayTime, value: timeStr });
          }
        }
      }
    });

    // Sort times chronologically
    timeOptionsTemp.sort((a, b) => {
      if (a.value === 'all') return -1;
      if (b.value === 'all') return 1;
      return a.value.localeCompare(b.value);
    });

    setTimeOptions(timeOptionsTemp);

    // Filter attendees based on the current selections
    filterAttendees();
  };

  // Separate function to filter attendees based on selections
  const filterAttendees = () => {
    if (!attendees) return;

    const filtered = attendees.docs.filter(doc => {
      const attendee = doc.data();
      let attendeeDate: moment.Moment | null = null;

      // Prioritize eventTime as it has the correct time data
      if (attendee.eventTime) {
        attendeeDate = moment(attendee.eventTime, 'dddd MMM DD, hh:mm a').tz('America/Chicago');
      } else if (attendee.eventDate) {
        // Fall back to eventDate only if needed
        attendeeDate = moment.utc(attendee.eventDate).tz('America/Chicago');
      }

      // If no valid date, include only if all dates selected
      if (!attendeeDate || !attendeeDate.isValid()) {
        return selectedDate === 'all';
      }

      // Filter by date
      if (selectedDate !== 'all') {
        const attendeeDateStr = attendeeDate.format('YYYY-MM-DD');
        if (attendeeDateStr !== selectedDate) return false;
      }

      // Filter by module
      if (selectedModule !== 'all') {
        const moduleMatches =
          attendee.productName && attendee.productName.includes(getModuleNameByValue(selectedModule));
        if (!moduleMatches) return false;
      }

      // Filter by time
      if (selectedTime !== 'all') {
        // Use the consistent timezone-aware approach to get hour and minute
        let hour = parseInt(attendeeDate.format('H'));
        let minute = parseInt(attendeeDate.format('m'));
        const attendeeTimeStr = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        if (attendeeTimeStr !== selectedTime) return false;
      }

      return true;
    });

    setFilteredAttendees(filtered as QueryDocumentSnapshot<CheckAttendee>[]);
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const AttendeeListItem = ({ doc, index }: { doc: QueryDocumentSnapshot<CheckAttendee>; index: number }) => {
    const attendee: CheckAttendee = doc.data();

    return (
      <div className={`${styles.attendeeRow} ${index % 2 ? styles.attendeeRowEven : styles.attendeeRowOdd}`}>
        <div className={styles.attendeeInfo}>
          <h2 className={styles.attendeeName}>
            {attendee.firstName} {attendee.lastName}
          </h2>
          <div className={styles.attendeeDetails}>
            <div>
              <strong>Email/Phone:</strong> {attendee.email} {attendee.phone}
            </div>
            <div>
              <strong>Module:</strong> {attendee.productName || 'Not specified'}
            </div>
            <div>
              <strong>Date/Time:</strong>{' '}
              {attendee.eventTime
                ? moment(attendee.eventTime, 'dddd MMM DD, hh:mm a')
                    .tz('America/Chicago')
                    .format('ddd, MMM D, YYYY h:mm A')
                : attendee.eventDate
                ? moment.utc(attendee.eventDate).tz('America/Chicago').format('ddd, MMM D, YYYY h:mm A')
                : 'Date unavailable'}
            </div>
            <div>
              <strong>Learning Preference:</strong> {attendee.howLearn || 'Not specified'}
            </div>
            {attendee._checkedIn && (
              <div>
                <strong>Checked In:</strong>{' '}
                {moment(attendee._checkedIn).tz('America/Chicago').format('ddd, MMM D, YYYY h:mm A')}
              </div>
            )}
          </div>
        </div>
        <div className={styles.actionArea} style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minWidth: '80px',
            }}
          >
            <div style={{ marginBottom: '5px', fontSize: '14px' }}>Waiver Signed</div>
            <span
              style={{
                fontSize: '20px',
                color: attendee.waiverComplete ? 'green' : 'red',
                fontWeight: 'bold',
              }}
              title={`Waiver ${attendee.waiverComplete ? 'completed' : 'not completed'}`}
            >
              {attendee.waiverComplete ? '✓' : '❌'}
            </span>
          </div>
          <div className={styles.actionButton}>
            <Button
              buttonType={checkInMode ? 'primary' : 'secondary'}
              onClick={() => {
                setSelectedAttendee(doc as QueryDocumentSnapshot<CheckAttendee>);
                setDialogVisible(true);
              }}
              disabled={!checkInMode && attendee._checkedOut !== null && attendee._checkedOut !== undefined}
            >
              {checkInMode ? 'Check In' : 'Undo Check-In'}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const handleConfirmDialog = async () => {
    if (selectedAttendee) {
      const attendeeRef = selectedAttendee.ref;
      if (checkInMode) {
        // Check in - set the check-in timestamp
        await updateDoc(attendeeRef, {
          _checkedIn: Timestamp.now(),
        });
      } else {
        // Undo check-in - remove the check-in timestamp
        await updateDoc(attendeeRef, {
          _checkedIn: null,
        });
      }
    }
    setDialogVisible(false);
    setSelectedAttendee(null);
  };

  const handleUpgradeClick = () => {
    if (selectedAttendee) {
      setDialogVisible(false);
      setUpgradeDialogVisible(true);
    }
  };

  // Dialog to confirm check-in/check-out or show waiver warning
  const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ isOpen, onClose, onConfirm, onUpgrade, attendee, checkInMode }) => {
    if (!isOpen || !attendee) return null;
    const attendeeData = attendee.data();
    const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);

    // Check if the attendee is eligible for an upgrade (Towing 101 or Trailer Demo only)
    const isEligibleForUpgrade =
      attendeeData.productName === 'Towing 101 Session' ||
      attendeeData.productName === 'Ford Pro Trailer Backup Assist™ & Pro Trailer Hitch Assist™ Demonstration';

    useEffect(() => {
      if (isOpen && attendee && checkInMode && !attendeeData.waiverComplete) {
        // Generate the waiver URL with UTM parameters for the QR code
        // Ensure the protocol is included for proper QR code scanning
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        const attendeeId = attendeeData.id;

        // Create UTM parameters
        const utmParams = new URLSearchParams({
          utm_source: 'checkin_screen',
          utm_medium: 'qr_code',
          utm_campaign: 'waiver_completion',
          utm_content: attendeeData.eventID || 'dallas',
        }).toString();

        // Complete waiver URL with protocol included
        const waiverUrl = `${baseUrl}/pre?attendeeID=${attendeeId}&${utmParams}`;

        // Generate QR code
        import('qrcode').then(QRCode => {
          QRCode.toDataURL(waiverUrl, { width: 200, margin: 2 })
            .then((url: string) => {
              setQrCodeUrl(url);
            })
            .catch((err: Error) => {
              console.error('Error generating QR code:', err);
            });
        });
      }
    }, [isOpen, attendee, checkInMode, attendeeData]);

    // Redirect to waiver completion page
    const handleCompleteOnDevice = () => {
      onClose();

      // Create UTM parameters
      const utmParams = new URLSearchParams({
        utm_source: 'checkin_screen',
        utm_medium: 'direct',
        utm_campaign: 'waiver_completion',
        utm_content: attendeeData.eventID || 'dallas',
      }).toString();

      // Navigate to the waiver completion page
      window.location.href = `/pre?attendeeID=${attendeeData.id}&${utmParams}&redirect=/dallas/in/`;
    };

    // If checking in and waiver not complete
    if (checkInMode && !attendeeData.waiverComplete) {
      return (
        <Dialog title="Waiver Not Completed" onClose={onClose} className="waiver-dialog" style={{ zIndex: 9999 }}>
          <div className="p-4" style={{ textAlign: 'center' }}>
            <div style={{ marginBottom: '16px' }}>
              <Typography displayStyle="body-1-regular">
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  {attendeeData.firstName} {attendeeData.lastName} has not completed the waiver.
                </span>
              </Typography>
            </div>

            <div style={{ marginBottom: '24px' }}>
              <Typography displayStyle="body-1-regular">
                The attendee must complete the waiver before checking in. They can scan this QR code with their device
                or complete it on this device.
              </Typography>
            </div>

            {qrCodeUrl && (
              <div style={{ margin: '24px 0' }}>
                <img
                  src={qrCodeUrl}
                  alt="Waiver QR Code"
                  style={{ width: '200px', height: '200px', margin: '0 auto' }}
                />
              </div>
            )}

            <div style={{ display: 'flex', justifyContent: 'center', gap: '16px', marginTop: '24px' }}>
              <Button buttonType="tertiary" onClick={onClose}>
                Cancel
              </Button>
              <Button buttonType="primary" onClick={handleCompleteOnDevice}>
                Complete on this device
              </Button>
            </div>
          </div>
        </Dialog>
      );
    }

    // Standard confirm dialog for check-in/out
    return (
      <Dialog
        title={checkInMode ? 'Check In Confirmation' : 'Check Out Confirmation'}
        onClose={onClose}
        className="waiver-dialog"
        style={{ zIndex: 9999 }}
      >
        <div className="p-4">
          <Typography displayStyle="body-1-regular">
            Are you sure you want to {checkInMode ? 'check in' : 'undo check-in for'}{' '}
            <strong>
              {attendeeData.firstName} {attendeeData.lastName}
            </strong>
            ?
          </Typography>
        </div>
        <DialogActionsBar>
          <Button buttonType="tertiary" onClick={onClose}>
            Cancel
          </Button>
          {checkInMode && onUpgrade && isEligibleForUpgrade && (
            <Button 
              buttonType="secondary" 
              onClick={onUpgrade}
              disabled={attendeeData._checkedOut !== null && attendeeData._checkedOut !== undefined}
            >
              ✨Upgrade✨
            </Button>
          )}
          <Button 
            buttonType={checkInMode ? 'primary' : 'secondary'} 
            onClick={onConfirm}
            disabled={checkInMode && attendeeData._checkedOut !== null && attendeeData._checkedOut !== undefined}
          >
            Confirm
          </Button>
        </DialogActionsBar>
      </Dialog>
    );
  };

  // New component for the upgrade QR code dialog - similar to CheckOut.tsx
  const UpgradeQRDialog: React.FC<UpgradeQRDialogProps> = ({ isOpen, onClose, attendee }) => {
    if (!isOpen || !attendee) return null;
    const attendeeData = attendee.data();
    const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
    
    useEffect(() => {
      if (isOpen && attendee) {
        // Generate the upgrade URL with attendeeID
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        const attendeeId = attendee.id;
        
        // Create UTM parameters
        const utmParams = new URLSearchParams({
          utm_source: 'checkin_screen',
          utm_medium: 'qr_code',
          utm_campaign: 'module_upgrade',
          utm_content: attendeeData.eventID || 'dallas',
        }).toString();
        
        // Complete upgrade URL with protocol included
        const upgradeUrl = `${baseUrl}/dallas-upgrade?attendeeID=${attendeeId}&${utmParams}`;
        
        // Generate QR code
        import('qrcode').then(QRCode => {
          QRCode.toDataURL(upgradeUrl, { width: 200, margin: 2 })
            .then((url: string) => {
              setQrCodeUrl(url);
            })
            .catch((err: Error) => {
              console.error('Error generating QR code:', err);
            });
        });
      }
    }, [isOpen, attendee, attendeeData]);
    
    return (
      <Dialog title="Upgrade Experience" onClose={onClose} className="waiver-dialog" style={{ zIndex: 9999 }}>
        <div className="p-4" style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: '16px' }}>
            <Typography displayStyle="body-1-regular">
              <span style={{ fontWeight: 'bold' }}>
                Upgrade {attendeeData.firstName} {attendeeData.lastName}'s experience
              </span>
            </Typography>
          </div>
          
          {qrCodeUrl && (
            <div style={{ margin: '24px 0' }}>
              <img
                src={qrCodeUrl}
                alt="Upgrade QR Code"
                style={{ width: '200px', height: '200px', margin: '0 auto' }}
              />
              <div style={{ marginTop: '8px' }}>
                <Typography displayStyle="body-1-regular">
                  Scan to select a new module & pay
                </Typography>
              </div>
            </div>
          )}
          
          <div style={{ display: 'flex', justifyContent: 'center', gap: '16px', marginTop: '24px' }}>
            <Button buttonType="primary" onClick={onClose}>
              OK
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  if (userLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.loadingSpinner}>Loading...</div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className={styles.container}>
        <div className={styles.noResultsMessage}>
          <Typography displayStyle="display-2-small-regular">Please log in to access this page.</Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography displayStyle="display-2-small-regular">
          {eventID.charAt(0).toUpperCase() + eventID.slice(1)} Event:{' '}
          {checkInMode ? 'Check In Attendees' : 'Check Out Attendees'}
        </Typography>
      </div>

      <div className={styles.tabContainer}>
        <div
          className="segmented-control flex mb-4"
          style={{ border: '1px solid #0066B2', borderRadius: '4px', overflow: 'hidden' }}
        >
          <div
            className={`segment ${checkInMode ? 'active' : ''}`}
            style={{
              flex: 1,
              padding: '10px 16px',
              textAlign: 'center',
              cursor: 'pointer',
              backgroundColor: checkInMode ? '#0066B2' : 'transparent',
              color: checkInMode ? 'white' : '#0066B2',
              fontWeight: 500,
              transition: 'background-color 0.2s, color 0.2s',
            }}
            onClick={() => setCheckInMode(true)}
          >
            Check In
          </div>
          <div
            className={`segment ${!checkInMode ? 'active' : ''}`}
            style={{
              flex: 1,
              padding: '10px 16px',
              textAlign: 'center',
              cursor: 'pointer',
              backgroundColor: !checkInMode ? '#0066B2' : 'transparent',
              color: !checkInMode ? 'white' : '#0066B2',
              fontWeight: 500,
              transition: 'background-color 0.2s, color 0.2s',
            }}
            onClick={() => setCheckInMode(false)}
          >
            Checked In
          </div>
        </div>
      </div>

      <div className={styles.filtersContainer ? styles.filtersContainer : 'mb-6 p-4 border rounded'}>
        <Typography displayStyle="body-1-regular">Filter Attendees</Typography>

        {/* Search Input */}
        <div className="mb-4 mt-2">
          <Typography displayStyle="body-1-regular">Search</Typography>
          <div className="mt-2">
            <input
              type="text"
              className="w-full p-2 border rounded"
              placeholder="Search by name, email, or phone"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 mb-2">
          {/* Date Filter */}
          <div>
            <Typography displayStyle="body-1-regular">Date</Typography>
            <div className="mt-2">
              <select
                className="w-full p-2 border rounded"
                value={selectedDate}
                disabled={isLoadingFilters}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedDate(e.target.value)}
              >
                {dateOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Module Filter */}
          <div>
            <Typography displayStyle="body-1-regular">Module</Typography>
            <div className="mt-2">
              <select
                className="w-full p-2 border rounded"
                value={selectedModule}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedModule(e.target.value)}
              >
                {moduleOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Time Filter */}
          <div>
            <Typography displayStyle="body-1-regular">Time</Typography>
            <div className="mt-2">
              <select
                className="w-full p-2 border rounded"
                value={selectedTime}
                disabled={timeOptions.length <= 1}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedTime(e.target.value)}
              >
                {timeOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <Typography displayStyle="body-1-regular">
            Showing {filteredAttendees?.length || 0} attendee{(filteredAttendees?.length || 0) !== 1 ? 's' : ''}
          </Typography>
        </div>
      </div>

      {attendeesLoading ? (
        <div className={styles.loadingSpinner}>
          <Typography displayStyle="body-1-regular">Loading attendees...</Typography>
        </div>
      ) : attendeesError ? (
        <div className={styles.noResultsMessage}>
          <Typography displayStyle="body-1-regular">Error loading attendees: {attendeesError.message}</Typography>
        </div>
      ) : filteredAttendees.length === 0 ? (
        <div className={styles.noResultsMessage}>
          <Typography displayStyle="body-1-regular">No attendees found matching the selected filters.</Typography>
        </div>
      ) : (
        <div className={styles.listContainer}>
          {filteredAttendees.map((doc, index) => (
            <AttendeeListItem key={doc.id} doc={doc} index={index} />
          ))}
        </div>
      )}

      <ConfirmDialog
        isOpen={dialogVisible}
        onClose={() => setDialogVisible(false)}
        onConfirm={handleConfirmDialog}
        onUpgrade={handleUpgradeClick}
        attendee={selectedAttendee}
        checkInMode={checkInMode}
      />

      {upgradeDialogVisible && selectedAttendee && (
        <UpgradeQRDialog
          isOpen={upgradeDialogVisible}
          onClose={() => {
            setUpgradeDialogVisible(false);
            setSelectedAttendee(null);
          }}
          attendee={selectedAttendee}
        />
      )}
    </div>
  );
}

export default CheckInScreen;
