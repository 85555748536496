import React, { useEffect, useRef } from 'react';
import { Model } from 'survey-core';
import * as SurveyCore from 'survey-core';
import { Survey } from 'survey-react-ui';
import { useSearchParams } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { PaymentIntent, Stripe, StripeEmbeddedCheckoutOptions, loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

import { analytics, logEvent } from '@/firebase';

import 'survey-core/defaultV2.min.css';
import '@/assets/css/custom-fds.css';
import { fordCSS, themeJSON } from '@/assets/survey/FordTheme';

import baseSurvey from './BootcampForm.json';

import { FunctionFactory } from 'survey-core';

import styles from './_index.module.css';
import moment from 'moment-timezone';

// No Bookeo import/initialization for dallas-after

SurveyCore.setLicenseKey('NDBhNThlYzYtN2EwMy00ZTgxLWIyNGQtOGFkZWJkM2NlNjI3OzE9MjAyNS0wMS0wNA==');
let stripe: Stripe | null;
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PK);
stripePromise.then(ret => {
  stripe = ret;
});

const EVENT_ID = 'dallas';
const EARLY_BIRD_DATE = new Date('2025-03-13T00:00:00-05:00'); // Using Central Time since event is in Dallas

const EARLY_BIRD_PRICING = new Date() < EARLY_BIRD_DATE ? true : false;
if (EARLY_BIRD_PRICING) {
  console.log('Early Bird Pricing Active', EARLY_BIRD_DATE);
} else {
  console.log('Early Bird Pricing Not Active', EARLY_BIRD_DATE);
}

const moduleMapping = {
  'towing101': {
    name: 'Towing 101 Session',
    bookeoId: '41570P9A4PL19504EFB3E0',
    priceId: import.meta.env.VITE_ENV === 'production'
      ? 'price_1QrNseCVHUcuQutKicaeaMdL' // prod
      : 'price_1QtJrbCVHUcuQutKxqKv93n3' // stage/dev
  },
  'trailerDemo': {
    name: 'Ford Pro Trailer Backup Assist™ & Pro Trailer Hitch Assist™ Demonstration',
    bookeoId: '41570U4PLUH19504F1898E',
    priceId: import.meta.env.VITE_ENV === 'production'
      ? 'price_1QrNssCVHUcuQutKZ1FvD4pI' // prod
      : 'price_1QtJrKCVHUcuQutKfzme3ouy' // stage/dev
  },
  'trailerBackUp': {
    name: 'Hands-On Trailer Backup Experience',
    bookeoId: '41570EN9ER419504EE98B8',
    priceId: import.meta.env.VITE_ENV === 'production'
      ? EARLY_BIRD_PRICING ? 'price_1Qu37tCVHUcuQutK0kEslhwm' : 'price_1QrNt7CVHUcuQutKdz3tVkHA' // prod
      : EARLY_BIRD_PRICING ? 'price_1Qu3COCVHUcuQutKCiZaWnWg' : 'price_1QtJr1CVHUcuQutKJgvpOt73' // stage/dev
  },
  'ultimateTowing': {
    name: 'The Ultimate Towing Training Experience',
    bookeoId: '415703X9WWY19504ECB0EA',
    priceId: import.meta.env.VITE_ENV === 'production'
      ? EARLY_BIRD_PRICING ? 'price_1Qu37TCVHUcuQutKWDYMFma7' : 'price_1QrNtICVHUcuQutKQni4P946' // prod
      : EARLY_BIRD_PRICING ? 'price_1Qu3D1CVHUcuQutK3M4zLRkE' : 'price_1QtJqjCVHUcuQutKjx4whM89' // stage/dev
  }
};

// Create reverse lookup map
const bookeoToModuleMap = Object.entries(moduleMapping).reduce((acc, [key, value]) => {
  acc[value.bookeoId] = key;
  return acc;
}, {} as { [key: string]: string });

const getModuleLabel = (regularText: string, regularPrice: number | null, additionalInfo?: string) => {
  let result = '';
  
  if (!regularPrice) {
    result = regularText; // For free sessions
  } else if (new Date() < EARLY_BIRD_DATE) {
    const discountedPrice = regularPrice * 0.8; // 20% off for early bird
    const baseText = regularText.split(':')[0];
    result = `${baseText}: <span class="crossed-price">$${regularPrice}</span><span class="early-bird-price">Early Bird Price: $${discountedPrice}</span>`;
  } else {
    result = regularText;
  }
  
  // Add the additional info in italic if provided
  if (additionalInfo) {
    result += `<br><i>${additionalInfo}</i>`;
  }
  
  return result;
};

function validateEmail(this: any, [questionName]: string[]) {
  console.log('[validateEmail]', arguments);
  if (!questionName) {
    this.returnResult(true);
    return;
  }

  const email = this.survey.getValue(questionName);

  if (!email) {
    this.returnResult(true);
    return;
  }

  fetch(`https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}validateEmail-e2jtv773lq-uc.a.run.app`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  })
    .then(response => {
      response.json().then(res => {
        const { results } = res;

        let valid = true;

        // bad emails are rejected
        if (results?.valid === false) {
          valid = false;
        }

        // disposable email services are rejected
        if (results?.is_disposable === true) {
          valid = false;
        }

        // reject delivery_confidence below 20
        if (results?.delivery_confidence < 20) {
          valid = false;
        }

        // typos are rejected with correction
        if (results.did_you_mean) {
          valid = false;
        }

        if (!valid) {
          this.question.errors = [];
        }

        this.returnResult(valid);
      });
    })
    .catch(err => {
      alert(err);
    });
}

FunctionFactory.Instance.register('validateEmail', validateEmail, true);

// Set the title before creating the survey model
baseSurvey.title = 'Ford Towing Bootcamp Dallas-After Registration';

interface StripeMetadata {
  promotion_code?: string;
  discount_amount?: string;
}

const Main = () => {
  const [showCheckout, setShowCheckout] = React.useState(false);
  const [clientSecret, setClientSecret] = React.useState();
  const [transactionID, setTransactionID] = React.useState();
  const [searchParams] = useSearchParams();
  const isMounted = useRef(true);
  const [survey] = React.useState(() => {
    const surveyModel = new Model(baseSurvey);
    surveyModel.css = fordCSS;
    surveyModel.applyTheme(themeJSON);
    surveyModel.questionErrorLocation = "bottom";
    
    // Update the module choices with dynamic pricing
    const moduleQuestion = surveyModel.getQuestionByName('selectedModule');
    if (moduleQuestion) {
  moduleQuestion.choices = [
    {
      value: moduleMapping.towing101.bookeoId,
      text: getModuleLabel("Towing 101 Session (60 min session): FREE", null)
    },
    {
      value: moduleMapping.trailerDemo.bookeoId,
      text: getModuleLabel("Ford Pro Trailer Backup Assist™ & Pro Trailer Hitch Assist™ Demonstration (30 min session): FREE", null, "Optional entry to any Towing 101 session (60 min) included with registration for this course!")
    },
    {
      value: moduleMapping.trailerBackUp.bookeoId,
      text: getModuleLabel("Hands-On Trailer Backup Experience (2 hr Session): Starting at $200", 200, "Towing 101 Session is included in this experience")
    },
    {
      value: moduleMapping.ultimateTowing.bookeoId,
      text: getModuleLabel("The Ultimate Towing Training Experience (3 hr Session): Starting at $400", 400, "Towing 101 Session is included in this experience")
    }
  ];
    }
    
    // Allow HTML in choice labels
    surveyModel.onTextMarkdown.add(function(survey, options) {
      options.html = options.text;
    });
    
    return surveyModel;
  });

  // Handle URL parameter for module selection
  useEffect(() => {
    const moduleParam = searchParams.get('module');
    const storedModule = sessionStorage.getItem('selectedModule');
    
    if (moduleParam) {
      const mappedModule = moduleMapping[moduleParam as keyof typeof moduleMapping];
      if (mappedModule) {
        survey.setValue('selectedModule', mappedModule.bookeoId);
      }
    } else if (storedModule) {
      const mappedModule = moduleMapping[storedModule as keyof typeof moduleMapping];
      if (mappedModule) {
        survey.setValue('selectedModule', mappedModule.bookeoId);
      }
      sessionStorage.removeItem('selectedModule');
    }

    // No Bookeo ID setting for dallas-after
  }, [searchParams, survey]);

  // Add cleanup for component unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const options: StripeEmbeddedCheckoutOptions = {
    clientSecret: clientSecret || '',
    onComplete: async () => {
      console.log('[stripe] Checkout completed, clientSecret:', clientSecret);
      console.log('[stripe] Stripe instance available:', !!stripe);
      
      const selectedBookeoId = survey.getValue('selectedModule');
      const moduleKey = bookeoToModuleMap[selectedBookeoId];
      const selectedModule = moduleMapping[moduleKey as keyof typeof moduleMapping];
      const price = survey.getValue('priceTotal');
      const regularPrice = 
        selectedBookeoId === moduleMapping.trailerBackUp.bookeoId
          ? 200
          : selectedBookeoId === moduleMapping.ultimateTowing.bookeoId
            ? 400
            : 0;
      const earlyBirdDiscount = EARLY_BIRD_PRICING && regularPrice > 0 ? regularPrice * 0.2 : 0; // 20% off

      if (isMounted.current) {
        setShowCheckout(false);
      }

      // Send analytics events
      const analyticsPromise = (async () => {
        let promoCode = null;
        let promoValue = 0;

        // First try to get promotion details from Stripe
        if (stripe && clientSecret) {
          console.log('[stripe] Attempting to retrieve payment intent');
          
          try {
            const result = await stripe.retrievePaymentIntent(clientSecret);
            console.log('[stripe] Payment intent retrieved:', result);
            
            if (result.paymentIntent && isMounted.current) {
              const metadata = (result.paymentIntent as PaymentIntent & { metadata?: StripeMetadata }).metadata || {};
              promoCode = metadata.promotion_code || null;
              promoValue = metadata.discount_amount ? parseFloat(metadata.discount_amount) : 0;
            }
          } catch (error) {
            console.error('[stripe] Error retrieving payment intent:', error);
          }
        }

        // Send single purchase event with all promotion information
        console.log('[purchase] Sending purchase event', {
          hasPromoCode: !!promoCode,
          promoValue,
          hasEarlyBird: earlyBirdDiscount > 0,
        });

        await logEvent(analytics, 'purchase', {
          transaction_id: transactionID,
          value: price,
          currency: 'USD',
          tax: 0,
          shipping: 0,
          items: [{
            item_id: selectedBookeoId,
            item_name: selectedModule.name,
            price: regularPrice,
            quantity: 1,
          }],
          ...(earlyBirdDiscount > 0 && {
            promotion_id: 'early_bird',
            promotion_name: 'Early Bird Pricing',
            discount: earlyBirdDiscount,
          }),
          ...(promoCode && {
            promotion_id: promoCode,
            promotion_name: promoCode,
            discount: promoValue + (earlyBirdDiscount || 0),
          }),
        });

        // Wait a moment for analytics event to be sent
        console.log('[purchase] Waiting for analytics event to be sent...');
        await new Promise(resolve => setTimeout(resolve, 1000));
        console.log('[purchase] Analytics event complete');
      })();

      // Wait for analytics to complete before redirecting
      try {
        await analyticsPromise;
        console.log('[complete] Analytics finished, redirecting to thanks page');
        window.location.href = '/purchaseThanks';
      } catch (error) {
        console.error('[complete] Error in analytics:', error);
        // Still redirect even if there was an error
        window.location.href = '/purchaseThanks';
      }
    },
  };

  useEffect(() => {
    const scrollingElement = document.scrollingElement || document.body;
    if (clientSecret) {
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
      setTimeout(() => {
        scrollingElement.scrollTop = scrollingElement.scrollHeight;
      }, 3000);
    }
  }, [clientSecret]);

  survey.onPanelVisibleChanged.add((sender, options) => {
    if (!options.visible) {
      var questions = options.panel.questions;
      for (var i = 0; i < questions.length; i++) {
        var question = questions[i];
        sender.setValue(question.name, undefined);
      }
    }
  });

  survey.onValueChanged.add((sender, options) => {
    console.log('[onValueChanged] Event triggered:', {
      name: options.name,
      value: options.value,
      question: options.question,
    });
    
    if (options.name === 'selectedModule') {
      console.log('[onValueChanged] Selected module changed:', {
        moduleValue: options.value,
        moduleKey: bookeoToModuleMap[options.value],
      });
      
      const moduleKey = bookeoToModuleMap[options.value];
      const selectedModule = moduleMapping[moduleKey as keyof typeof moduleMapping];
      
      console.log('[onValueChanged] Preparing analytics event:', {
        moduleKey,
        selectedModule,
        price: 
          options.value === moduleMapping.trailerBackUp.bookeoId
            ? EARLY_BIRD_PRICING
                ? 160
                : 200
            : options.value === moduleMapping.ultimateTowing.bookeoId
              ? EARLY_BIRD_PRICING
                  ? 320
                  : 400
              : 0,
        earlyBirdActive: EARLY_BIRD_PRICING,
      });
      
      const analyticsEvent = {
        items: [{
          item_id: options.value, // bookeoId as item_id
          item_name: selectedModule.name,
          price: 
            options.value === moduleMapping.trailerBackUp.bookeoId
              ? EARLY_BIRD_PRICING
                  ? 160
                  : 200
              : options.value === moduleMapping.ultimateTowing.bookeoId
                ? EARLY_BIRD_PRICING
                    ? 320
                    : 400
                : 0,
        }],
        item_list_name: 'Towing Bootcamp Modules',
        item_list_id: 'dallas_modules',
      };
      
      console.log('[onValueChanged] Sending analytics event:', analyticsEvent);
      
      try {
        logEvent(analytics, 'select_item', analyticsEvent);
        console.log('[onValueChanged] Analytics event sent');
      } catch (error: unknown) {
        console.error('[onValueChanged] Analytics event failed:', error);
      }
    }
  });

  survey.onComplete.add((sender, options) => {
    const selectedBookeoId = sender.getValue('selectedModule');
    const moduleKey = bookeoToModuleMap[selectedBookeoId];
    const selectedModule = moduleMapping[moduleKey as keyof typeof moduleMapping];
    
    sender.setValue('eventID', EVENT_ID);
    sender.setValue('moduleName', selectedModule.name);
    sender.setValue('cartDate', new Date());
    console.log('onComplete', sender.data);

    // Create default booking data for dallas-after
    const defaultDate = moment.tz('2025-01-01 00:00:00', 'America/Chicago');
    const defaultBookingData = {
      startTime: 'Wednesday Jan 1, 12:00 am',
      productId: selectedBookeoId,
      slot: {
        eventId: 'dallas-after-default',
        startTime: defaultDate.toISOString(),
        endTime: defaultDate.add(1, 'hour').toISOString(),
      },
      bookeoKey: 'dallas-after-key',
      customFieldId: 'dallas-after-field',
      seats: 1,
    };

    const cart = {
      ...sender.data,
      source: 'dallas-after', // Mark as dallas-after cart
      bookeo_booking: defaultBookingData,
    };

    const price = sender.getValue('priceTotal');
    const regularPrice = 
      selectedBookeoId === moduleMapping.trailerBackUp.bookeoId
        ? 200
        : selectedBookeoId === moduleMapping.ultimateTowing.bookeoId
          ? 400
          : 0;
    const earlyBirdDiscount = EARLY_BIRD_PRICING && regularPrice > 0 ? regularPrice * 0.2 : 0; // 20% off

    console.log('[onComplete] Preparing checkout event:', {
      price,
      regularPrice,
      earlyBirdDiscount,
      EARLY_BIRD_PRICING,
    });
    
    logEvent(analytics, 'begin_checkout', {
      currency: 'USD',
      value: price,
      items: [{
        item_id: selectedBookeoId,
        item_name: selectedModule.name,
        price: regularPrice,
        quantity: 1,
      }],
      ...(earlyBirdDiscount > 0 && {
        promotion_id: 'early_bird',
        promotion_name: 'Early Bird Pricing',
        discount: earlyBirdDiscount,
      }),
    });

    // save data to firestore carts collection
    setShowCheckout(true);
    fetch(`https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}saveCart-e2jtv773lq-uc.a.run.app`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: cart,
      }),
    })
      .then(response => {
        response.json().then(data => {
          setTransactionID(data.cartID);
          const thisCartID = data.cartID;

          options.showDataSavingSuccess();

          fetch(`https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}createPaymentIntent-e2jtv773lq-uc.a.run.app`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              cartID: thisCartID,
              data: sender.data,
              source: 'dallas-after',
              priceID: moduleMapping[
                Object.keys(moduleMapping).find(
                  key => moduleMapping[key as keyof typeof moduleMapping].bookeoId === sender.data.selectedModule,
                ) as keyof typeof moduleMapping
              ].priceId,
            }),
          }).then(response => {
            response.json().then(data => {
              setClientSecret(data.clientSecret);
            });
          });
        });
      })
      .catch(err => {
        console.error(err);
        Sentry.captureException(err, {
          tags: {
            page: 'Dallas-After',
            location: 'surveyOnComplete',
            type: 'Stripe Payment Intent Error',
          },
        });
      });
  });

  return (
    <div className={styles.lightContent}>
      <div className={styles.contentSection}>
        <div id="signup"></div>
        {!showCheckout ? (
          <Survey model={survey}></Survey>
        ) : clientSecret ? (
          <EmbeddedCheckoutProvider options={options} stripe={stripePromise}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        ) : (
          <div className={styles.loadingSpinner}>
            <div className={styles.loadingSpinner__progress}></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Main;
