import React from 'react';
import { Typography } from '@ford/ford-ui-components';
import styles from './_index.module.css';

const Main = () => {
  return (
    <div className={styles.lightContent}>
      <div className={styles.contentSection}>
        <Typography displayStyle="display-3-small-regular" displayColor="text-onlight-strong">
          Terms and Conditions
        </Typography>

        <div className={styles.mainContent}>
          <div className={styles.section}>
            <div className={styles.sectionTitleWrapper}>
              <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
                GENERAL SAFETY
              </Typography>
            </div>
            <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
              <ul>
                <li className={styles.listItem}>
                  Everyone wants to have a fun, safe Experience. Please follow all safety instructions of Ford Towing Bootcamp
                  personnel and other Experience personnel.
                </li>
                <li className={styles.listItem}>
                  If you feel that you cannot safely execute any Experience activity, please let a Ford Towing Bootcamp staff
                  member know. We are here to assist.
                </li>
                <li className={styles.listItem}>
                  Ford Towing Bootcamp reserves the right, in our discretion, to remove from the Experience anyone that we
                  determine to pose a threat to the health or safety of any participant or attendee of the Experience.
                </li>
              </ul>
            </Typography>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitleWrapper}>
              <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
                SAFE DRIVING
              </Typography>
            </div>
            <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
              <ul>
                <li className={styles.listItem}>
                  If you do not have any experience towing, let us know. We are here to help you have fun and learn to drive
                  safely.
                </li>
                <li className={styles.listItem}>
                  If our Ford Towing Bootcamp personnel determines that your driving poses a threat to the safety of yourself
                  or others, Ford Towing Bootcamp personnel reserves the right to terminate your experience.
                </li>
                <li className={styles.listItem}>
                  You may not participate as a driver if you are impaired by or have any alcohol or performance-impairing
                  drugs in your possession at any time during the Experience. If you require medications for a health
                  condition that may impair your driving performance, please confer with a Ford Towing Bootcamp staff member
                  before the Experience so that we can assist you.
                </li>
                <li className={styles.listItem}>
                  All drivers must be over 18 years old and hold a valid driver's license.
                </li>
              </ul>
            </Typography>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitleWrapper}>
              <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
                ILLNESS PROTOCOL
              </Typography>
            </div>
            <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
              <ul>
                <li className={styles.listItem}>
                  If you have a fever, please do not come to the Event. Call (866) 277-2076 and advise Ford Towing Bootcamp
                  staff that you have a fever and cannot attend.
                </li>
                <li className={styles.listItem}>
                  It is imperative for you (and your guests) to self-monitor for the following symptoms:
                  <ul className={styles.nestedList}>
                    <li className={styles.listItem}>Fever or chills</li>
                    <li className={styles.listItem}>Cough</li>
                    <li className={styles.listItem}>Fatigue</li>
                    <li className={styles.listItem}>Muscle or body aches</li>
                    <li className={styles.listItem}>Headache</li>
                    <li className={styles.listItem}>New loss of taste or smell</li>
                    <li className={styles.listItem}>Sore throat</li>
                    <li className={styles.listItem}>Congestion or runny nose</li>
                    <li className={styles.listItem}>Nausea or vomiting</li>
                    <li className={styles.listItem}>Diarrhea</li>
                  </ul>
                </li>
                <li className={styles.listItem}>
                  Call (888) 367-3192 to reschedule should you be experiencing any of the symptoms listed above at the time of
                  your event.
                </li>
              </ul>
            </Typography>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitleWrapper}>
              <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
                COVID-19 PROTOCOL
              </Typography>
            </div>
            <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
              <ul>
                <li className={styles.listItem}>
                  If you have a known exposure to COVID-19 you should test yourself after five days of exposure and monitor
                  your symptoms. If you become symptomatic at any point, please do not come to the event. Please call (888)
                  367-3192 and we will assist you in rescheduling your Event.
                </li>
                <li className={styles.listItem}>
                  If you have contracted COVID-19, you should not come to the event until 5 days have passed and you are fever
                  free without fever reducing medications. You may come after five days of symptoms but will need to wear a
                  mask for the remaining five calendar days.
                </li>
              </ul>
            </Typography>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitleWrapper}>
              <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
                FEES
              </Typography>
            </div>
            <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
              <ul>
                <li className={styles.listItem}>Guests must be at least 10 years of age to attend.</li>
                <li className={styles.listItem}>
                  To drive, guests must be at least 18 years of age and must present a valid driver's license at event
                  registration.
                </li>
                <li className={styles.listItem}>There is one provided Ford F-150 vehicle per two driving participants.</li>
                <li className={styles.listItem}>
                  Please note that all participants will be responsible for lodging and travel to and from the experience.
                </li>
              </ul>
            </Typography>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitleWrapper}>
              <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
                CANCELATION POLICY
              </Typography>
            </div>
            <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
              <ul>
                <li className={styles.listItem}>72 hours or more before your experience &ndash; receive a full refund minus a $50 processing charge</li>
                <li className={styles.listItem}>Less than 72 hours before your experience &ndash; no refund</li>
              </ul>
            </Typography>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitleWrapper}>
              <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
                RESCHEDULE POLICY
              </Typography>
            </div>
            <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
              <ul>
                <li className={styles.listItem}>
                  You may reschedule your experience 72 hours or more before your experience for a $50 rescheduling fee,
                  subject to availability.
                </li>
              </ul>
            </Typography>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitleWrapper}>
              <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
                GUEST CHANGES
              </Typography>
            </div>
            <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
              <ul>
                <li className={styles.listItem}>
                  Need to switch participants? No problem. If you need to change the name of a guest, or cancel an
                  accompanying guest from your reservation, you can simply contact Ford Towing Bootcamp Headquarters at
                  info@fordtowingbootcamp.com or (888) 367-3192.
                </li>
              </ul>
            </Typography>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitleWrapper}>
              <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
                WEATHER AND UNANTICIPATED EXPERIENCES
              </Typography>
            </div>
            <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
              <ul>
                <li className={styles.listItem}>
                  In the experience of severe or inclement weather, Ford Towing Bootcamp may determine that it is in the best
                  interest of safety to either delay or suspend an Experience.
                </li>
                <li className={styles.listItem}>
                  Ford Towing Bootcamp personnel have the authority to mitigate on-course risks, including those related to
                  lightning and high winds. All participants must comply with the directions of Ford Towing Bootcamp personnel
                  that are intended to mitigate identified course hazards.
                </li>
                <li className={styles.listItem}>
                  Ford Towing Bootcamp is not liable for any failure which occurs as a result or consequence of any cause
                  beyond the control of Ford Towing Bootcamp, including strikes, labor disputes, government restrictions or
                  regulations, limitations on travel or gathering of groups, limitations or restrictions on hotels, travel or
                  transportation facilities, commodities or supplies, or other calamities or casualties, acts of war,
                  terrorism, disaster, civil disorder, epidemic, pandemic, or public health emergency, or severe weather
                  experience or other Act of God (individually or collectively, a &ldquo;Force Majeure&rdquo;). Ford Towing
                  Bootcamp may, in its sole discretion, resume or reschedule any Experience which is canceled, postponed or
                  terminated due to Force Majeure.
                </li>
              </ul>
            </Typography>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitleWrapper}>
              <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
                EXPERIENCE PRIVACY POLICY
              </Typography>
            </div>
            <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
              <p>
                The base Ford Towing Bootcamp Privacy Policy is available at{' '}
                <a href="https://www.ford.com/help/privacy/" target="_blank">
                  https://www.ford.com/help/privacy/
                </a>
              </p>

              <p>
                In addition, Ford Towing Bootcamp collects and uses information as follows:
              </p>
              <ul>
                <li>
                  We collect personal information from you to:
                  <ul>
                    <li>Enroll you in Ford Towing Bootcamp Experiences</li>
                    <li>Manage and administer the Ford Towing Bootcamp Experiences</li>
                    <li>Provide the Experience-related services to you</li>
                    <li>Comply with legal obligations</li>
                    <li>
                      Pursue legitimate interests, such as the effective and efficient operation of our business and those of
                      third parties such as our sponsors and vendors
                    </li>
                    <li>
                      Correspond with you regarding the Experience and provide other information we believe you may be
                      interested in
                    </li>
                    <li>Market our services, including by using your name and/or image, as described further below</li>
                    <li>Evaluate our services</li>
                  </ul>
                </li>
                <li>
                  Data Sharing: We share your information with:
                  <ul>
                    <li>Our sponsors and vendors</li>
                    <li>Third parties when needed to provide supplies and services</li>
                    <li>Other companies that provide goods and services that might be of interest to you</li>
                    <li>Governmental agencies that have jurisdiction over our activities</li>
                  </ul>
                </li>
                <li>Data Retention: We retain your data for as long as it is required for our business purposes.</li>
                <li>
                  Cookies: We use cookies to track movement through our registration process, remember your preferences and
                  improve your user experience. Some cookies are required for our registration process to function. Other
                  cookies can be turned off through your browser settings.
                </li>
              </ul>
            </Typography>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitleWrapper}>
              <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
                PHOTO AND VIDEO RELEASE
              </Typography>
            </div>
            <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
              <p>
                By entering into the Experience location, you and any individuals accompanying you each grant to Ford Towing
                Bootcamp and Ford the unqualified right to film, photograph, videotape and otherwise record you, in any form
                or format, and grant the perpetual right to use, publish, broadcast and exhibit, without compensation, your
                photo, recording, likeness or image (&ldquo;Image&rdquo;) for any purpose.
              </p>
            </Typography>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitleWrapper}>
              <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
                PERSONAL PHOTOGRAPHY
              </Typography>
            </div>
            <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
              <p>
                While you are welcome to take personal photographs of your party, please do not take photographs of others at
                the Experience without their permission.
              </p>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
