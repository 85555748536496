import { initializeApp } from 'firebase/app';
import { setConsent, getAnalytics, logEvent } from 'firebase/analytics';

// Add type declaration for gtag
declare global {
  interface Window {
    gtag: (command: string, ...args: any[]) => void;
  }
}

setConsent({
  analytics_storage: 'denied',
  ad_storage: 'denied',
  ad_personalization: 'denied',
  ad_user_data: 'denied',
});

const firebaseConfig = {
  apiKey: 'AIzaSyC7Y6UtH27Ih7hFMgiTaVOsEuFI3KPMIPg',
  authDomain: 'ford-towing-bootcamp.firebaseapp.com',
  projectId: 'ford-towing-bootcamp',
  storageBucket: 'ford-towing-bootcamp.appspot.com',
  messagingSenderId: '756797076545',
  appId: '1:756797076545:web:3aad0468d4bb4f0d9982c0',
  measurementId: import.meta.env.VITE_GA_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

// Enable debug mode for development and staging environments
const analyticsConfig = import.meta.env.VITE_ENV !== 'production' 
  ? { debug_mode: true }
  : undefined;

const analytics = getAnalytics(app);

// Set debug_mode if not in production
if (import.meta.env.VITE_ENV !== 'production') {
  window.gtag('config', import.meta.env.VITE_GA_MEASUREMENT_ID, {
    debug_mode: true
  });
}

export { analytics, logEvent };
