import React from 'react';
import GlobeIcon from '../../images/icons/ford/globe.svg';
import PrivacyChoicesIcon from '../../images/icons/ford/privacy-choices.svg';
import FordSignature from '../../images/logo/ford-signature.svg';
import './_index.css';

interface FooterLink {
  text: string;
  url?: string;
  onClick?: () => void;
}

interface FordFooterProps {
  supportedLanguages?: string[]; // Array of 2-char language codes
  onLanguageChange?: (languageCode: string) => void;
  oneTrustEnabled?: boolean;
  otherLinks?: FooterLink[];
  imgStyle?: React.CSSProperties;
  onLinkClick?: (linkText: string) => void;
}

const FordFooter: React.FC<FordFooterProps> = ({
  supportedLanguages,
  onLanguageChange = () => {},
  oneTrustEnabled = false,
  otherLinks = [],
  onLinkClick
}) => {
  const openCookieSettings = () => {
    const { Optanon } = window as any;
    Optanon && Optanon.ToggleInfoDisplay && Optanon.ToggleInfoDisplay();
  };

  const handleLinkClick = (link: FooterLink) => {
    onLinkClick?.(link.text);
    link.onClick?.();
  };

  const footerLinks = [
    { text: '© 2025 Ford Motor Company', url: 'https://www.ford.com' },
    ...otherLinks,
    { text: 'Privacy Notice', url: 'https://www.ford.com/help/privacy/' },
    ...(oneTrustEnabled ? [{ text: 'Cookie Settings', onClick: openCookieSettings }] : []),
    { text: 'Your Privacy Choices', url: 'https://www.ford.com/help/privacy/ccpa/' },
    { text: 'Interest Based Ads', url: 'https://www.ford.com/help/privacy/#interest' },
  ];

  return (
    <div className="footer-container">
      {/* Language Change Section - Only show if supported languages are provided */}
      {supportedLanguages && supportedLanguages.length > 0 && (
        <div className="language-change">
          <img 
            src={GlobeIcon}
            alt="Globe"
            className="globe-icon"
          />
          <select
            className="language-select"
            onChange={(e) => onLanguageChange(e.target.value)}
          >
            {supportedLanguages.map((code) => (
              <option key={code} value={code}>
                {new Intl.DisplayNames([code], { type: 'language' }).of(code)}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Links Section */}
      <div className="links-section">
        {footerLinks.map((link, index) => (
          <a
            key={index}
            {...(link.url ? { href: link.url } : {})}
            className="footer-link"
            onClick={(e) => {
              if (!link.url) {
                e.preventDefault();
              }
              handleLinkClick(link);
            }}
            {...(link.url ? { target: "_blank", rel: "noopener noreferrer" } : {})}
          >
            {link.text}
            {link.text === 'Your Privacy Choices' && (
              <img
                src={PrivacyChoicesIcon}
                alt="Privacy Choices"
                className="privacy-icon"
              />
            )}
          </a>
        ))}
      </div>

      {/* Ford Logo */}
      <div className="ford-logo">
        <a 
          href="https://www.ford.com" 
          target="_blank" 
          rel="noopener noreferrer"
          onClick={() => onLinkClick?.('Ford.com')}
        >
          <img
            src={FordSignature}
            alt="Ford Logo"
            className="ford-signature"
          />
        </a>
      </div>
    </div>
  );
};

export default FordFooter;
