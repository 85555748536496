/// <reference types="@types/google.maps" />

import { Model } from 'survey-core';
import * as SurveyCore from 'survey-core';
import { Survey } from 'survey-react-ui';

import * as Sentry from '@sentry/react';
import styles from '../pre/_index.module.css'; // Reuse the pre-survey styles

import 'survey-core/defaultV2.min.css';
import '@/assets/css/survey-override.css';

import { themeJSON } from '@/assets/survey/FordTheme';
import { prepareForSurvey, prepareSurveyOnQuestionAdded } from '@/helpers/surveyTemplatesAll';

import '@/assets/survey/surveysjs_renderers/CheckboxVOITabs';

import baseSurvey from './PostSurvey.json';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Attendee } from '@/types/attendee';

const extractUTM = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const utm: Record<string, string> = {};

  for (const [key, value] of params.entries()) {
    if (['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].includes(key)) {
      utm[key] = value;
    }
  }

  return utm;
};

SurveyCore.setLicenseKey('NDBhNThlYzYtN2EwMy00ZTgxLWIyNGQtOGFkZWJkM2NlNjI3OzE9MjAyNS0wMS0wNA==');

const surveyModel = new Model(baseSurvey);
surveyModel.applyTheme(themeJSON);

// Set up event handlers immediately after creating the survey
prepareForSurvey(surveyModel);
prepareSurveyOnQuestionAdded(null, { survey: surveyModel });

// No need to set itemCssClass as we're using itemComponent in the JSON directly

const PostSurvey = () => {
  const [searchParams] = useSearchParams();
  const attendeeID = searchParams.get('attendeeID');

  const [attendee, setAttendee] = useState<Attendee>();
  const [attendeeLoading, setAttendeeLoading] = useState(true);
  // We need to keep the setState even if we don't use the state directly
  const [, setPreSurveyData] = useState<any>(null);

  useEffect(() => {
    if (attendeeID) {
      const fetchAttendee = async () => {
        try {
          const response = await fetch(
            `https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}getAttendee-e2jtv773lq-uc.a.run.app`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                attendeeID,
                surveyType: 'preTD',
              }),
            },
          );

          if (!response.ok) {
            console.error('Error fetching attendee:', response);
            setAttendeeLoading(false);
            throw new Error('Failed to fetch attendee');
          }

          const attendeeData = await response.json();
          console.log('attendeeData', attendeeData);

          // Fetch pre-survey data
          try {
            const preSurveyResponse = await fetch(
              `https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}getSurveyData-e2jtv773lq-uc.a.run.app`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  attendeeID,
                  surveyType: 'preTD',
                }),
              },
            );

            if (preSurveyResponse.ok) {
              const preSurveyData = await preSurveyResponse.json();
              console.log('preSurveyData', preSurveyData);
              setPreSurveyData(preSurveyData);

              // Pre-populate survey with data from pre-survey
              surveyModel.title = `${attendeeData.productName} - Post Event Survey`;

              // Set Ford ownership based on pre-survey
              const isFordOwner = preSurveyData?.fordOwner || false;

              surveyModel.mergeData({
                surveyType: 'postTD',
                attendeeID,
                eventID: attendeeData.eventID,
                cartID: attendeeData.cartID,
                firstName: attendeeData.firstName,
                lastName: attendeeData.lastName,
                email: attendeeData.email,
                phone: attendeeData.phone,
                fordOwnerPost: isFordOwner,
                moduleName: attendeeData.moduleName,
                preSurveyEmailOptIn: preSurveyData?.fordEmailOptIn || 1, // Default to 1 (Yes) if not found
                _utm: extractUTM(),
              });
            } else {
              console.log('No pre-survey data found or error fetching');

              // invalid pre-survey data do not let them do the survey
              setAttendeeLoading(false);
              throw new Error('Failed to fetch survey');
            }
          } catch (error) {
            console.error('Error fetching pre-survey data:', error);

            // invalid pre-survey data do not let them do the survey
            setAttendeeLoading(false);
            throw error;
          }

          setAttendee(attendeeData as Attendee);
          setAttendeeLoading(false);
        } catch (error) {
          console.error('Error fetching attendee:', error);
          setAttendeeLoading(false);
          throw error;
        }
      };

      fetchAttendee();
    }
  }, [attendeeID]);

  useEffect(() => {
    surveyModel.onComplete.add((sender, options) => {
      sender.setValue('surveyDate', new Date());

      console.log('onComplete', sender.data);

      const data = {
        ...sender.data,
      };

      // save data to firestore carts collection
      fetch(`https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}saveSurvey-e2jtv773lq-uc.a.run.app`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          survey: data,
        }),
      })
        .then(() => {
          options.showDataSavingSuccess();
          const redirect = searchParams.get('redirect');
          if (redirect) {
            window.location.href = redirect;
          }
        })
        .catch(err => {
          console.error(err);
          Sentry.captureException(err, {
            tags: {
              page: 'PostDrive',
              location: 'surveyOnComplete',
              type: 'Survey Save Error',
            },
          });
        });
    });
  }, [searchParams]);

  return (
    <>
      {attendee ? (
        attendee._used ? (
          <>
            <div className="fds-layout-grid__inner">
              <div className="fds-layout-grid__cell--span-12">
                <div className="fmc-billboard towing_billboard fmc-billboard--justify-start fmc-billboard--align-start">
                  <div className="fmc-billboard__image event_billboard_background"></div>
                </div>
              </div>
            </div>
            <div className={styles.lightContent}>
              <div className={styles.contentSection}>
                <h1 className="fds-color__text--tertiary fmc-type--heading4 fmc-mb-6 fmc-mb-5-md">
                  Post-Survey Already Completed
                </h1>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.lightContent}>
            <div className={styles.contentSection}>
              <Survey model={surveyModel} />
            </div>
          </div>
        )
      ) : !attendeeLoading ? (
        <>
          <div className="fds-layout-grid__inner">
            <div className="fds-layout-grid__cell--span-12">
              <div className="fmc-billboard towing_billboard fmc-billboard--justify-start fmc-billboard--align-start">
                <div className="fmc-billboard__image event_billboard_background"></div>
              </div>
            </div>
          </div>
          <div className={styles.lightContent}>
            <div className={styles.contentSection}>
              <h1 className="fds-color__text--tertiary fmc-type--heading4 fmc-mb-6 fmc-mb-5-md">Invalid ID</h1>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.lightContent}>
          <div className={styles.contentSection}>
            <div>Loading Post-EventSurvey...</div>
          </div>
        </div>
      )}
    </>
  );
};

export default PostSurvey;
