import React from 'react';
import { Typography, Accordion, AccordionItem } from '@ford/ford-ui-components';
import { analytics, logEvent } from '@/firebase';
import styles from './_index.module.css';

const Main = () => {
  const handleFaqClick = (question: string) => {
    logEvent(analytics, 'select_content', {
      content_type: 'faq',
      content_id: question.toLowerCase().replace(/[^a-z0-9]+/g, '_'),
      item_name: question
    });
  };

  return (
    <div className={styles.lightContent}>
      <div className={styles.contentSection}>
        <Typography displayStyle="display-3-small-regular" displayColor="text-onlight-strong">
          Frequently Asked Questions
        </Typography>

        <div className={styles.accordionSection}>
          <Accordion type="multiple">
            <AccordionItem 
              title="Will I be using my own vehicle during the event?" 
              bodyText="No, Ford will be supplying the F-150® truck that you will be using during the driving experience."
              value="vehicle"
              onClick={() => handleFaqClick("Will I be using my own vehicle during the event?")}
            />

            <AccordionItem 
              title="What are the requirements to make a reservation?" 
              bodyText="To make a reservation, you must be 18 years of age or older and have a valid driver's license."
              value="requirements"
              onClick={() => handleFaqClick("What are the requirements to make a reservation?")}
            />

            <AccordionItem 
              title="What does the experience include?" 
              value="experience"
              onClick={() => handleFaqClick("What does the experience include?")}
              contentSlot={
                <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                  <p>Depending on what module you select, your experience can vary. If you choose Towing 101 Curriculum, you'll start with a classroom course covering basic towing concepts you will want to know before getting behind-the-wheel. If you choose the Pro Trailer Backup Assist™  and Pro Trailer Hitch Assist™ (30-minute tech demo), you will be a passenger with a CDL-trained instructor as they demonstrate the Ford towing assist technology. If you choose the Trailer Backup Experience, you will receive 60 minutes of hands-on towing instruction which includes basics such as hitching up and backing up a trailer from your in-vehicle instructor. If you choose the Ultimate Towing Experience, you will receive all of the concepts previously mentioned, along with additional time behind-the-wheel on a closed course designed to help build your confidence and improve your skills. After your selected training is complete, interactive learning stations will be available at your convenience, providing opportunities to help you further develop an understanding of towing basics. Snacks to refuel will be provided, and meals will be available for purchase on-site.</p>
                </Typography>
              }
            />

            <AccordionItem 
              title="I've never towed a trailer before. Is that okay?" 
              bodyText="Absolutely! Whether you're considering your first trailer-towing adventure or have towed before and are looking for additional training, our Ford Towing Bootcamp is designed to boost your confidence and help build your towing knowledge and skills. You will be participating with other attendees with a variety of experience levels."
              value="experience-level"
              onClick={() => handleFaqClick("I've never towed a trailer before. Is that okay?")}
            />

            <AccordionItem 
              title="Will an instructor be in the vehicle with me?" 
              bodyText="Our team of expert towing instructors will lead your Bootcamp experience throughout the day. For all driving experiences, one (1) instructor will be riding in the vehicle with up to two (2) driving participants, providing continual communication and offering towing tips, tricks, and techniques."
              value="instructor"
              onClick={() => handleFaqClick("Will an instructor be in the vehicle with me?")}
            />

            <AccordionItem 
              title="How long should I plan for my experience?" 
              bodyText="Duration varies depending on the learning module you select, ranging from 30 minutes to three hours. If you select our longest module, the Ultimate Towing Experience, your registration time includes one hour of classroom instruction and two hours of behind-the-wheel time.  If your schedule permits, reserve additional time after your scheduled training to explore our interactive learning stations. Please be sure to arrive 15 minutes prior to your reservation time for check-in."
              value="duration"
              onClick={() => handleFaqClick("How long should I plan for my experience?")}
            />

            <AccordionItem 
              title="How much does it cost to attend?" 
              bodyText="The cost varies depending on when you registered, the selected learning module, and whether you own or lease a Ford vehicle. Your registration fee includes expert instruction from licensed trainers, use of a Ford F-150® truck on a closed driving course (if a driving experience is selected), and snacks to refuel."
              value="cost"
              onClick={() => handleFaqClick("How much does it cost to attend?")}
            />

            <AccordionItem 
              title="Can I bring a guest?" 
              bodyText="Of course! You can invite two (2) driving guests for a standard reservation price. Driving guests receive the same curriculum and behind-the-wheel training as reservation holders. All driving guests must be 18 years of age or older and present a valid driver's license at event check-in. You may also invite one (1) non-driving guest for every driving participant in your reservation party. Non-driving guests can attend at no added cost."
              value="guests"
              onClick={() => handleFaqClick("Can I bring a guest?")}
            />

            <AccordionItem 
              title="How old does someone have to be to attend as a guest?" 
              bodyText="This is a family-friendly event, and children 10 years of age or older are welcome! To get behind-the-wheel, all driving guests must be 18 years of age or older and present a valid driver's license at event check-in. All adults and children must be properly restrained with an individual seatbelt or appropriately sized child restraint as per NHTSA guidelines (restraint not supplied at event and must be brought by reservation holder)."
              value="guest-age"
              onClick={() => handleFaqClick("How old does someone have to be to attend as a guest?")}
            />

            <AccordionItem 
              title="Can I bring my pet with me?" 
              bodyText="Sorry, no pets allowed (other than service animals)."
              value="pets"
              onClick={() => handleFaqClick("Can I bring my pet with me?")}
            />

            <AccordionItem 
              title="What should I bring with me?" 
              value="bring"
              onClick={() => handleFaqClick("What should I bring with me?")}
              contentSlot={
                <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                  <p>Prepare for the driving experiences! We recommend bringing the following:</p>
                  <ul>
                    {[
                      'Your reservation confirmation email (digital or printed copy)',
                      'Valid driver\'s license for anyone planning to drive on-site',
                      'Sunglasses',
                      'Sunscreen',
                      'Any medication you may need',
                      'Comfortable clothing appropriate for the weather',
                      'Closed-toe shoes that provide comfort and control while driving, such as thin-soled boots, sneakers, or running shoes.',
                      'Plan for unexpected weather changes with a jacket, hat, and/or rain shell'
                    ].map((item, index) => (
                      <li key={index} className={styles.listItem}>{item}</li>
                    ))}
                  </ul>
                  <p><strong>Please note that alcohol is not permitted on-site.</strong></p>
                </Typography>
              }
            />

            <AccordionItem 
              title="What if I want to reschedule my Ford Towing Bootcamp reservation?" 
              value="reschedule"
              onClick={() => handleFaqClick("What if I want to reschedule my Ford Towing Bootcamp reservation?")}
              contentSlot={
                <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                  To change your Bootcamp reservation, call or email our team at{' '}
                  <a href="mailto:info@fordtowingbootcamp.com">info@fordtowingbootcamp.com</a> or{' '}
                  <a href="tel:888-367-3192">(888) 367-3192</a>. You may reschedule your experience 72 hours or more before your experience for a $50 rescheduling fee, subject to availability at another event.
                </Typography>
              }
            />

            <AccordionItem 
              title="What if I need to cancel my Ford Towing Bootcamp reservation?" 
              value="cancel"
              onClick={() => handleFaqClick("What if I need to cancel my Ford Towing Bootcamp reservation?")}
              contentSlot={
                <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                  <p>We hope this won't be the case! In the event you need to cancel your reservation, the following terms apply:</p>
                  <ul>
                    <li className={styles.listItem}>72 hours or more before your experience: You will receive a full refund minus a $50 processing charge</li>
                    <li className={styles.listItem}>Less than 72 hours before your experience: no refunds</li>
                  </ul>
                </Typography>
              }
            />

            <AccordionItem 
              title="Is the Ford Towing Clinic ADA-accessible?" 
              value="ada"
              onClick={() => handleFaqClick("Is the Ford Towing Clinic ADA-accessible?")}
              contentSlot={
                <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                  The Ford Towing Bootcamp is held at the AT&T Stadium in Dallas and they are ADA-accessible. For specific requirements or requests, please contact{' '}
                  <a href="tel:888-367-3192">(888) 367-3192</a>, or email{' '}
                  <a href="mailto:info@fordtowingbootcamp.com">info@fordtowingbootcamp.com</a>.
                </Typography>
              }
            />

            <AccordionItem 
              title="Will the event be accessible by wheelchair or power scooter?" 
              bodyText="The venue is accessible by wheelchair or power scooter. To participate in the Ford Towing Bootcamp driving portion, you will need to be able to safely operate a motor vehicle."
              value="wheelchair-access"
              onClick={() => handleFaqClick("Will the event be accessible by wheelchair or power scooter?")}
            />

            <AccordionItem 
              title="Will there be accessible parking spaces?" 
              bodyText="Yes, accessible parking will be available near the main entrance of the facility."
              value="accessible-parking"
              onClick={() => handleFaqClick("Will there be accessible parking spaces?")}
            />

            <AccordionItem 
              title="Help! What if I need further assistance?" 
              value="help"
              onClick={() => handleFaqClick("Help! What if I need further assistance?")}
              contentSlot={
                <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                  If you've hit a roadblock anywhere in the registration process, or if you need any additional help, please email our team at{' '}
                  <a href="mailto:info@fordtowingbootcamp.com">info@fordtowingbootcamp.com</a> or call{' '}
                  <a href="tel:888-367-3192">(888) 367-3192</a>. A Ford Towing Bootcamp Specialist will be happy to get you back on track.
                </Typography>
              }
            />

            <AccordionItem 
              title="Can I transfer my ticket to someone else?" 
              bodyText="We are not accepting ticket transfers. Please see our refund policy for contact information and conditions."
              value="ticket-transfer"
              onClick={() => handleFaqClick("Can I transfer my ticket to someone else?")}
            />

            <AccordionItem 
              title="What payment methods are accepted?" 
              bodyText="We accept Visa, Mastercard, American Express, and Discover."
              value="payment-methods"
              onClick={() => handleFaqClick("What payment methods are accepted?")}
            />

            <AccordionItem 
              title="What happens if the event is postponed or canceled?" 
              bodyText="You will receive a refund if this event is postponed or canceled."
              value="event-cancellation"
              onClick={() => handleFaqClick("What happens if the event is postponed or canceled?")}
            />

            <AccordionItem 
              title="Will there be on-site registration?" 
              bodyText="There will be on-site registration. Please reference the pricing grid to understand how day of pricing may differ."
              value="onsite-registration"
              onClick={() => handleFaqClick("Will there be on-site registration?")}
            />

            <AccordionItem 
              title="I didn't receive my confirmation email. What should I do?" 
              value="missing-confirmation"
              onClick={() => handleFaqClick("I didn't receive my confirmation email. What should I do?")}
              contentSlot={
                <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                  Reach out to{' '}
                  <a href="mailto:info@fordtowingbootcamp.com">info@fordtowingbootcamp.com</a> or call{' '}
                  <a href="tel:888-367-3192">(888) 367-3192</a>.
                </Typography>
              }
            />
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Main;
