import { Outlet } from 'react-router-dom';
import FordHeader from '@/assets/components/FordHeader';
import FordFooter from '@/assets/components/FordFooter';
import { useEffect, useState, useCallback } from 'react';
import { analytics, logEvent } from '@/firebase';
import styles from './_index.module.css';

const Layout = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 10);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check initial scroll position

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleNavClick = useCallback((linkText: string) => {
    logEvent(analytics, 'select_content', {
      content_type: 'navigation',
      content_id: linkText.toLowerCase().replace(/\s+/g, '_'),
      link_text: linkText
    });
  }, []);

  const handleFooterClick = useCallback((linkText: string) => {
    logEvent(analytics, 'select_content', {
      content_type: 'footer',
      content_id: linkText.toLowerCase().replace(/\s+/g, '_'),
      link_text: linkText
    });
  }, []);

  return (
    <div className={`${styles.layout_container} font-ford`}>
      <FordHeader
        navLinks={[
          { text: 'About', url: '/' },
          { text: 'Contact Us', url: '/contact' },
          { text: 'FAQ', url: '/faq' }
        ]}
        darkBackground={false}
        transparentBackground={!isScrolled}
        onNavClick={handleNavClick}
      />
      <div className={styles.ford_towing_content}>
        <Outlet />
      </div>
      <FordFooter
        oneTrustEnabled={true}
        otherLinks={[{ text: 'Program Terms & Conditions', url: '/terms' }]}
        onLinkClick={handleFooterClick}
      />
    </div>
  );
};

export default Layout;
