import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import App from '@/App';
import '@/index.css';
import '@/assets/scss/style.scss';

// Import Kendo UI theme for Dialog and other components
import '@progress/kendo-theme-default/dist/all.css';

import '@ford/gdux-design-foundation/dist/ford/styles/_variables.css';
import '@ford/gdux-design-foundation/dist/ford/styles/ford.css';
import '@ford/gdux-design-foundation/dist/ford/styles/gdux-ford.css';
import '@ford/gdux-design-foundation/dist/ford/fontFamilies/ford-font-families.css';

const container = document.getElementById('app');
const root = createRoot(container!);
const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
root.render(app);
