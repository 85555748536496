import React, { useState } from "react";
import { ComponentCollection, Serializer, RendererFactory } from "survey-core";
import { SurveyQuestionCheckbox, ReactQuestionFactory } from "survey-react-ui";
import { trim } from "lodash";
import { useTranslation } from "@/hooks/useTranslation";
import style from './_index.module.scss';

const VEHICLE_TYPES = ["Ford SUVs", "Ford Cars", "Ford Trucks"];

console.log('Module loaded, registering component...');

// First register the question type
ReactQuestionFactory.Instance.registerQuestion(
    "checkbox",
    (props: any): JSX.Element => {
        if (props.question.renderAs === "voi_tabs") {
            return React.createElement(CheckboxVOITabsQuestion, props);
        }
        return React.createElement(SurveyQuestionCheckbox, props);
    }
);

// Then register the renderer
RendererFactory.Instance.registerRenderer(
    "checkbox",
    "voi_tabs",
    "checkbox"
);

// Finally add the component collection
ComponentCollection.Instance.add({
    name: "fordvoi_tabs",
    title: "Ford VOI Tabs",
    questionJSON: {
        type: "checkbox",
        renderAs: "voi_tabs",
        maxSelectedChoices: 3,
        choicesOrder: "random",
        choicesByUrl: {
            url: "https://cdn.latitudewebservices.com/vehicles/ford.json",
            valueName: "id",
            titleName: "name",
            image: "image",
            type: "type"
        }
    }
});

console.log('ComponentCollection registration complete');

Serializer.addProperty("itemvalue", {
    name: "type",
    displayName: "type",
    type: "string",
    visibleIf: (obj) => {
        return obj.locOwner.renderAs === "voi_tabs";
    }
});

// Add the property for onlyInclude
Serializer.addProperty("checkbox", {
    name: "onlyInclude",
    category: "general",
    type: "string",
    visibleIf: (obj: any) => {
        return obj.renderAs === "voi_tabs";
    }
});

const TabsBody = ({ question, cssClasses }: any) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<string>(VEHICLE_TYPES[0]);
    let filteredItems = question?.visibleChoices || [];



    // Filter by onlyInclude if specified
    if (question?.onlyInclude?.length) {
        const onlyInclude = question.onlyInclude?.split(',').map((o: string) => trim(o) ? Number(trim(o)) : null) || [];
        if (onlyInclude.length > 0) {
            filteredItems = filteredItems.filter((item: any) => onlyInclude.includes(item.value));
        }
    }

    // Type filtering
    filteredItems = filteredItems.filter((item: any) => {
        return item.jsonObj.type?.toLowerCase() === activeTab.toLowerCase() ||
            item.jsonObj.type?.toLowerCase() === activeTab.replace('Ford ', '').toLowerCase();
    });

    if (!question?.visibleChoices?.length) {
        return <div>Loading...</div>;
    }

    return (
        <div className={style.voi_tabs_container}>
            <div className={style.tabs_header}>
                {VEHICLE_TYPES.map((tab) => (
                    <button
                        key={tab}
                        className={`${style.tab_button} ${activeTab === tab ? style.active : ''}`}
                        onClick={() => setActiveTab(tab)}
                    >
                        {t(tab.replace("Ford ", ""))}
                    </button>
                ))}
            </div>
            <div className={style.radio_group_voi}>
                {filteredItems.map((item: any) => {
                    const imageURL = `https://cdn.latitudewebservices.com/vehicles/images/${item.image}`;
                    const inputId = `checkbox_${question.name}_${item.value}`;
                    const isChecked = question.isItemSelected(item);

                    return (
                        <div
                            role="presentation"
                            key={item.value}
                            className={`${style.voi_option} ${isChecked ? style.voi_option_active : ''}`}
                            onClick={(e) => {
                                if (!(e.target as Element).closest('input')) {
                                    const isCurrentlySelected = question.isItemSelected(item);
                                    const currentSelectionCount = question.value?.length || 0;

                                    if (!isCurrentlySelected && currentSelectionCount >= question.maxSelectedChoices) {
                                        return;
                                    }

                                    question.clickItemHandler(item, !isCurrentlySelected);
                                }
                            }}
                        >
                            <img alt={item.text} src={imageURL} className={style.vehicle_img} />
                            <label htmlFor={inputId}>
                                {item.text.replace("-", "‑")}
                                <input
                                    type="checkbox"
                                    id={inputId}
                                    checked={isChecked}
                                    onChange={() => { }}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </label>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export class CheckboxVOITabsQuestion extends SurveyQuestionCheckbox {
    constructor(props: any) {
        super(props);
        (this as any).getBody = (cssClasses: any) => {
            return <TabsBody question={this.question} cssClasses={cssClasses} />;
        };
    }
}

console.log('Module registration complete'); 