import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FordSignature from '../../images/logo/ford-signature.svg';
import MenuIcon from '../../images/icons/ford/menu.svg';
import './_index.css';

interface NavLink {
  text: string;
  url?: string;
  onClick?: () => void;
}

interface FordHeaderProps {
  supportedLanguages?: string[]; // Array of 2-char language codes
  onLanguageClick?: () => void;
  navLinks?: NavLink[];
  transparentBackground?: boolean;
  darkBackground?: boolean;
  onNavClick?: (linkText: string) => void;
}

const FordHeader: React.FC<FordHeaderProps> = ({
  onLanguageClick = () => {},
  navLinks = [],
  transparentBackground = false,
  darkBackground = false,
  supportedLanguages,
  onNavClick
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isHovered, setIsHovered] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const headerClasses = `ford-header ${transparentBackground ? 'transparent' : ''} ${darkBackground ? 'dark' : ''} ${isHovered ? 'hovered' : ''} ${isMenuOpen ? 'menu-open' : ''}`;

  const handleLinkClick = (link: NavLink) => {
    onNavClick?.(link.text);
    link.onClick?.();
  };

  return (
    <header 
      className={headerClasses}
      onMouseEnter={() => transparentBackground && setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isMobile ? (
        <>
          <div className="mobile-header">
            <img 
              src={FordSignature} 
              alt="Ford Logo" 
              className={`ford-header-logo ${darkBackground ? 'invert' : ''}`}
            />
            {navLinks.length > 0 && (
              <button 
                className={`menu-button ${isMenuOpen ? 'opened' : ''}`}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                aria-expanded={isMenuOpen}
                aria-controls="mobile-menu"
              >
                <div className="lines">
                  <span></span>
                </div>
                <span className="sr-only">Menu</span>
              </button>
            )}
          </div>

          {/* Mobile Menu Drawer */}
          {isMenuOpen && (
            <div className="mobile-menu-drawer">
              <nav className="mobile-menu-nav">
                <ul className="mobile-menu-list">
                  {navLinks.map((link, index) => (
                    <li key={index}>
                      {link.url ? (
                        <Link 
                          to={link.url}
                          className="mobile-nav-link"
                          onClick={() => {
                            handleLinkClick(link);
                            setIsMenuOpen(false);
                          }}
                        >
                          {link.text}
                        </Link>
                      ) : (
                        <button 
                          className="mobile-nav-link"
                          onClick={() => {
                            handleLinkClick(link);
                            setIsMenuOpen(false);
                          }}
                        >
                          {link.text}
                        </button>
                      )}
                    </li>
                  ))}
                  {supportedLanguages && supportedLanguages.length > 0 && (
                    <li>
                      <button 
                        className="mobile-nav-link"
                        onClick={() => {
                          onLanguageClick();
                          setIsMenuOpen(false);
                        }}
                      >
                        Change Language
                      </button>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          )}
        </>
      ) : (
        <nav className="desktop-header">
          {navLinks.length > 0 && (
            <div className="nav-links">
              <ul className="nav-links-list">
                {navLinks.map((link, index) => (
                  <li key={index}>
                    {link.url ? (
                      <Link 
                        to={link.url}
                        className="nav-button"
                        onClick={() => handleLinkClick(link)}
                      >
                        {link.text}
                      </Link>
                    ) : (
                      <button 
                        className="nav-button"
                        onClick={() => handleLinkClick(link)}
                      >
                        {link.text}
                      </button>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <img 
            src={FordSignature} 
            alt="Ford Logo" 
            className={`ford-header-logo ${darkBackground ? 'invert' : ''}`}
          />
          <div className="nav-icons">
            <ul className="nav-icons-list">
              {supportedLanguages && supportedLanguages.length > 0 && (
                <li>
                  <button 
                    className="icon-button"
                    onClick={onLanguageClick}
                    aria-label="Change Language"
                  >
                    <span className={`icon-globe ${darkBackground ? 'invert' : ''}`} />
                  </button>
                </li>
              )}
            </ul>
          </div>
        </nav>
      )}
    </header>
  );
};

export default FordHeader;

