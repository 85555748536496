import React, { useRef, useState, useEffect } from 'react';
import { analytics, logEvent } from '@/firebase';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './_index.module.css';
import ReactSimplyCarousel from 'react-simply-carousel';

import { Button, Accordion, AccordionItem, Icon, Typography } from '@ford/ford-ui-components';

import logo from '@/assets/images/logo/built-ford-tough.svg';
import closeIcon from '@/assets/images/icons/ford/close.svg';
import checkIcon from '@/assets/images/icons/ford/check.svg';
import pauseIcon from '@/assets/images/icons/ford/pause.svg';

import hitching from '@/assets/images/hitching.jpg';
import classroom from '@/assets/images/classroom.jpg';
import reversing from '@/assets/images/reversing.jpg';

import darren_cindy from '@/assets/images/trainers/DarrenandCindy.jpg';
import terrio from '@/assets/images/trainers/Terrio.jpg';
import jae from '@/assets/images/trainers/Jae.jpg';
import chris from '@/assets/images/trainers/Christopher.jpg';
import jen from '@/assets/images/trainers/Jennifer.jpg';
import carolina from '@/assets/images/trainers/Carolina.jpg';

import selfHitch from '@/assets/images/self-hitch.jpg';
import team from '@/assets/images/team.jpg';

import carouselLeft from '@/assets/images/icons/ford/carousel-left.svg';
import carouselRight from '@/assets/images/icons/ford/carousel-right.svg';

import homeMedImage from '@/assets/images/billboard/home-med@2x.jpg';
import homeSmImage from '@/assets/images/billboard/home-sm@2x.jpg';

// Define proper types for the arrow props
type CustomArrowProps = {
  onClick?: () => void;
  direction: 'left' | 'right';
};

const Main = () => {
  const [searchParams] = useSearchParams();
  let displayLogo = logo;
  const dialogRef = useRef<HTMLDialogElement>(null);
  const instructorDialogRef = useRef<HTMLDialogElement>(null);
  const [currentInstructor, setCurrentInstructor] = useState<{
    name: string;
    bio: string;
  } | null>(null);
  const navigate = useNavigate();
  const [videoPlayer, setVideoPlayer] = useState<any>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  // Handle selectedModule URL parameter
  useEffect(() => {
    const moduleParam = searchParams.get('module');
    const bidParam = searchParams.get('bid');
    if (moduleParam) {
      sessionStorage.setItem('selectedModule', moduleParam);
    }
    if (bidParam) {
      sessionStorage.setItem('selectedBookeoId', bidParam);
    }
    if (moduleParam || bidParam) {
      document.getElementById('signup')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [searchParams]);

  const openDialog = () => {
    if (dialogRef.current) {
      dialogRef.current.showModal();
    }
  };

  const openInstructorDialog = (instructor: { name: string; bio: string }) => {
    setCurrentInstructor(instructor);
    if (instructorDialogRef.current) {
      instructorDialogRef.current.showModal();
    }
  };

  const instructors = [
    {
      id: 'darrencindy',
      name: 'Darren & Cindy Richardson',
      image: darren_cindy,
      shortBio:
        "Meet Darren and Cindy Richardson, trucking business owners who've towed everything from Super Bowl sleighs to F-16 fighter jets...",
      fullBio:
        "Darren and Cindy own their own trucking business and enjoy the adventures that towing has provided them over the years. They have over 40 years of towing experience and are experts in bumper towing. They once used F-150's to tow sleighs around the Super Bowl activation in Indianapolis. Darren and Cindy were also responsible for towing the Airforce display assets around the country to attend NASCAR races and festivals where they hauled an F-16 fighter jet!",
    },
    {
      id: 'terrio',
      name: 'Terrio "T" Davis',
      image: terrio,
      shortBio:
        'Meet Terrio "T" Davis, an event marketing professional and CDL-A driver with 16 years of experience...',
      fullBio:
        'Meet Terrio "T" Davis, an event marketing professional of 16 years and a CDL-A driver and driver trainer. He is an owner of his own trucking company with his partner Jae. Terrio has operated tour programs with F-350 with trailers and full semi-truck and trailers.',
    },
    {
      id: 'jae',
      name: 'Jae Davis',
      image: jae,
      shortBio: 'Meet Jae Davis, a training professional with over 12 years of experience and CDL-A driver...',
      fullBio:
        'Meet Jae Davis, a training professional with over 12 years of experience and CDL-A driver. She has delivered best-in-class consumer experiences and will helps students feel at ease and reduce towing anxiety! Jae is also an author of "You Do What" a guide to Experiential Marketing.',
    },
    {
      id: 'chris',
      name: 'Christopher Maheu',
      image: chris,
      shortBio: 'Meet Chris Maheu, a seasoned CDL-A driver and marketing tour professional...',
      fullBio:
        'Meet Chris Maheu, a seasoned CDL-A driver and marketing tour professional for festivals, concerts, sporting events and automotive events. He is a seasoned product educator and has operated tours hauling trailers from 8-42 feet with bumper, gooseneck and 5th wheel hitches.',
    },
    {
      id: 'jen',
      name: 'Jennifer Skarbek',
      image: jen,
      shortBio:
        'Meet Jen Skarbek, a training professional equipped for tour/production management with a CDL-A license...',
      fullBio:
        "Meet Jennifer Skarbek, a training professional equipped for tour/production management with a CDL-A license. She has worked on projects with large scale productions to small pop ups across the US. With her partner Chris, they are often on the road exploring the US and towing everything from M&M's to RV's!",
    },
    {
      id: 'carolina',
      name: 'Carolina Paredes',
      image: carolina,
      shortBio:
        'Meet F-150 ambassador (@madebycarolina) and seasoned Ford Towing Bootcamp instructor Carolina Paredes...',
      fullBio:
        'Meet F-150 ambassador (@madebycarolina) and seasoned Ford Towing Bootcamp instructor Carolina Paredes. Carolina has experience towing everything from equipment trailers to promotional vehicles. She enjoys hands on creativity and loves teaching people how to tow so they can unlock new adventures!',
    },
  ];

  const togglePlayPause = () => {
    console.log('Toggle clicked, videoPlayer:', videoPlayer, 'isPlaying:', isPlaying);
    if (videoPlayer && videoPlayer.ref) {
      try {
        const player = videoPlayer.ref;
        if (isPlaying) {
          console.log('Attempting to pause');
          player.pause();
        } else {
          console.log('Attempting to play');
          player.play();
        }
        setIsPlaying(!isPlaying);
      } catch (error) {
        console.error('Error toggling play/pause:', error);
      }
    } else {
      console.warn('Video player not initialized');
    }
  };

  // Add back the CustomArrow component
  const CustomArrow = ({ onClick, direction }: CustomArrowProps) => (
    <button
      onClick={onClick}
      className={`${styles.carousel_button} ${
        direction === 'left' ? styles.carousel_button_left : styles.carousel_button_right
      }`}
      aria-label={direction === 'left' ? 'Previous' : 'Next'}
    >
      <img src={direction === 'left' ? carouselLeft : carouselRight} alt="" />
    </button>
  );

  return (
    <>
      <div className={styles.billboard}>
        {/* Video Background */}
        <div className={`hidden md:block ${styles.billboard_video_container}`}>
          <button
            className={styles.video_pause_button}
            onClick={togglePlayPause}
            aria-label={isPlaying ? 'Pause video' : 'Play video'}
          >
            <img src={pauseIcon} alt="" />
          </button>

          <div className={`${styles.video_wrapper} h-full`}>
            <img
              src="/src/assets/images/billboard/home-xl.jpg"
              alt="Ford Towing Background"
              className={styles.video_fallback}
            />
            <ReactPlayerLoader
              accountId="4095259350001"
              videoId="6366072280112"
              options={{
                autoplay: true,
                muted: true,
                loop: true,
                controls: false,
                playsinline: true,
                responsive: true,
              }}
              onSuccess={player => {
                console.log('Player initialized:', player);
                console.log('Available player methods:', Object.keys(player));
                setVideoPlayer(player);

                // Get the VideoJS player instance directly
                const videoJsPlayer = player.ref;
                videoJsPlayer.on('play', () => {
                  console.log('Play event fired');
                  setIsPlaying(true);
                });

                videoJsPlayer.on('pause', () => {
                  console.log('Pause event fired');
                  setIsPlaying(false);
                });

                // Use the VideoJS player instance for initial setup
                videoJsPlayer.muted(true);
                videoJsPlayer.play();

                const fallbackImg = document.querySelector(`.${styles.video_fallback}`);
                if (fallbackImg) {
                  (fallbackImg as HTMLElement).style.opacity = '0';
                }
              }}
              onFailure={error => {
                console.error('Player failed to initialize:', error);
                const fallbackImg = document.querySelector(`.${styles.video_fallback}`);
                if (fallbackImg) {
                  (fallbackImg as HTMLElement).style.opacity = '1';
                }
              }}
            />
          </div>
        </div>

        {/* Responsive Image Fallbacks */}
        <picture className={`block md:hidden ${styles.billboard_image_container}`}>
          <source media="(min-width: 640px)" srcSet={homeMedImage} />
          <img src={homeSmImage} alt="Ford Towing Background" />
        </picture>

        {/* Content Overlay */}
        <div className={styles.billboard_content}>
          <div className={styles.billboard_content_inner}>
            <h1 className={styles.billboard_heading}>
              Exclusive Courses.
              <br />
              Expert Guides.
              <br />
              Epic Adventures.
            </h1>

            <p className={styles.billboard_button}>
              <Button
                variant="filled"
                onClick={() => {
                  logEvent(analytics, 'select_content', {
                    content_type: 'signup_button',
                    content_id: 'signup_billboard',
                  });
                  document.getElementById('signup')?.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Sign Up Now
              </Button>
            </p>

            <button
              className={styles.details_button}
              onClick={() => {
                logEvent(analytics, 'select_content', {
                  content_type: 'disclaimer',
                  content_id: 'towing_disclaimer',
                });
                openDialog();
              }}
            >
              Important Details
            </button>
          </div>
        </div>
      </div>

      <dialog ref={dialogRef} className={styles.modal}>
        <button className={styles.modal_close} onClick={() => dialogRef.current?.close()} aria-label="Close">
          <img src={closeIcon} alt="Close" />
        </button>
        <div className={styles.modal_content}>
          <p className={styles.modal_text}>
            Towing capability varies based on cargo, vehicle configuration, accessories, and number and weight of
            passengers.
          </p>
        </div>
      </dialog>

      <div className={styles.lightContent}>
        <div className={styles.contentSection}>
          <h2 className="headline-3-small-semibold">Learn the art of towing with Ford</h2>

          <p className={`body-1-regular ${styles.paragraph}`}>
            Gear up for an unforgettable towing adventure! Join fellow enthusiasts and towing instructors at Ford Towing
            Bootcamp. This isn't just training; it's a chance to learn essential towing skills, experience Ford's latest
            towing technology, and push your limits in a safe and supportive environment. Choose your path: classroom
            learning with towing professionals, interactive demonstrations, or hands-on practice in our dedicated
            closed-course area. Get ready for fun, debunking common towing myths, and the confidence to tow!
          </p>
        </div>
      </div>

      <div className={styles.lightContent}>
        <div className={styles.contentSection}>
          <Accordion type="multiple">
            <AccordionItem
              title="Towing 101 Session (60 min)"
              bodyText="Get towing-ready with key terminology, capacity calculations, and pre-trip safety checks. Explore Ford's digital towing tools and join a live Q&A with a CDL instructor."
              value="1"
              onClick={() => {
                logEvent(analytics, 'select_content', {
                  content_type: 'accordion',
                  content_id: 'towing101',
                });
              }}
            />
            <AccordionItem
              title="Ford Pro Trailer Backup Assist™ & Pro Trailer Hitch Assist™ Demo"
              bodyText="See Ford towing tech in action as a Commercial Driver License (CDL) instructor demonstrates backup and hitch assist features to help in learning trailer maneuvering basics."
              value="2"
              onClick={() => {
                logEvent(analytics, 'select_content', {
                  content_type: 'accordion',
                  content_id: 'trailerDemo',
                });
              }}
            />
            <AccordionItem
              title="Hands-On Trailer Backup Experience (2 hrs)"
              bodyText="Build your towing skills with Towing 101 basics, a Ford Pro technology demonstration, plus an hour of hands-on practice hitching and backing up a trailer—coached by a CDL instructor."
              value="3"
              onClick={() => {
                logEvent(analytics, 'select_content', {
                  content_type: 'accordion',
                  content_id: 'trailerBackUp',
                });
              }}
            />
            <AccordionItem
              title="The Ultimate Towing Training Experience (3 hrs)"
              bodyText="Increase your towing confidence with Towing 101, a Ford Pro technology demonstration, hands-on trailer backup practice, plus 2 hours of closed-course driving, all guided by a CDL instructor."
              value="4"
              onClick={() => {
                logEvent(analytics, 'select_content', {
                  content_type: 'accordion',
                  content_id: 'ultimateTowing',
                });
              }}
            />
          </Accordion>
        </div>
      </div>

      <div className={styles.lightContent}>
        <div className={styles.contentSection}>
          <h2 className="headline-3-small-semibold">Bootcamp Experience Options</h2>

          <table className={styles.pricingGrid}>
            <thead>
              <tr>
                <th>Experience Options</th>
                <th>Cost</th>
                <th>
                  Early bird Discounts Offered
                  <br />
                  (2/17-3/12, 2025)
                </th>
                <th>How To Join Us</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className={styles.sessionTitle}>Towing 101 Session</div>
                  <div className={styles.sessionDuration}>60 minute session</div>
                </td>
                <td>*FREE</td>
                <td>-</td>
                <td>
                  <Button
                    variant="filled"
                    rightIcon={<Icon name="chevronRight" />}
                    onClick={() => {
                      logEvent(analytics, 'select_content', {
                        content_type: 'pricing_signup',
                        content_id: 'towing101',
                      });
                      sessionStorage.setItem('selectedModule', 'towing101');
                      document.getElementById('signup')?.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Sign Up
                  </Button>
                </td>
              </tr>
              <tr>
                <td>
                  <div className={styles.sessionTitle}>
                    Ford Pro Trailer Backup Assist™ & Pro Trailer Hitch Assist™ Demo
                  </div>
                  <div className={styles.sessionDuration}>30 minute session</div>
                </td>
                <td>*FREE</td>
                <td>-</td>
                <td>
                  <Button
                    variant="filled"
                    rightIcon={<Icon name="chevronRight" />}
                    onClick={() => {
                      logEvent(analytics, 'select_content', {
                        content_type: 'pricing_signup',
                        content_id: 'trailerDemo',
                      });
                      sessionStorage.setItem('selectedModule', 'trailerDemo');
                      document.getElementById('signup')?.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Sign Up
                  </Button>
                </td>
              </tr>
              <tr>
                <td>
                  <div className={styles.sessionTitle}>Hands-On Trailer Backup Experience</div>
                  <div className={styles.sessionDuration}>2 hour session</div>
                </td>
                <td>**Starting at $160</td>
                <td>
                  <div className={styles.centerWrapper}>
                    <img src={checkIcon} alt="Available" className={styles.checkIcon} />
                  </div>
                </td>
                <td>
                  <Button
                    variant="filled"
                    rightIcon={<Icon name="chevronRight" />}
                    onClick={() => {
                      logEvent(analytics, 'select_content', {
                        content_type: 'pricing_signup',
                        content_id: 'trailerBackUp',
                      });
                      sessionStorage.setItem('selectedModule', 'trailerBackUp');
                      document.getElementById('signup')?.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Sign Up
                  </Button>
                </td>
              </tr>
              <tr>
                <td>
                  <div className={styles.sessionTitle}>The Ultimate Towing Training Experience</div>
                  <div className={styles.sessionDuration}>3 hour session</div>
                </td>
                <td>**Starting at $320</td>
                <td>
                  <div className={styles.centerWrapper}>
                    <img src={checkIcon} alt="Available" className={styles.checkIcon} />
                  </div>
                </td>
                <td>
                  <Button
                    variant="filled"
                    rightIcon={<Icon name="chevronRight" />}
                    onClick={() => {
                      logEvent(analytics, 'select_content', {
                        content_type: 'pricing_signup',
                        content_id: 'ultimateTowing',
                      });
                      sessionStorage.setItem('selectedModule', 'ultimateTowing');
                      document.getElementById('signup')?.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Sign Up
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>

          <p className={`body-1-regular ${styles.paragraph}`} style={{ marginTop: 'var(--spacing-lg)' }}>
            *Opportunities to upgrade to the Hands-on Backing up Class or Full Towing Bootcamp will be available on-site
            for the full price rate.
            <br />
            **Starting at Price reflects Early Bird discount. Additional costs applied for guests of primary driver.
          </p>
        </div>
      </div>

      <div className={styles.darkContent}>
        <div className={styles.contentSection}>
          <Typography displayStyle="title-medium" displayColor="text-ondark-strong">
            The Ultimate Towing Training Experience will include
          </Typography>

          <div className={styles.cardGrid}>
            <div className={styles.card}>
              <img className={styles.cardImage} src={classroom} alt="Classroom instruction" />
              <Typography displayStyle="body-1-regular" displayColor="text-ondark-strong">
                Classroom session lead by both male and female towing professionals.
              </Typography>
            </div>

            <div className={styles.card}>
              <img className={styles.cardImage} src={hitching} alt="Hitching practice" />
              <Typography displayStyle="body-1-regular" displayColor="text-ondark-strong">
                Proactive hitching and unhitching in a low pressure learning environment.
              </Typography>
            </div>

            <div className={styles.card}>
              <img className={styles.cardImage} src={reversing} alt="F-150 and trailer" />
              <Typography displayStyle="body-1-regular" displayColor="text-ondark-strong">
                Real-world towing and backing up station on a closed course.
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.lightContent}>
        <div className={styles.contentSection}>
          <a id="signup"></a>
          <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
            Ready to tow like a pro?
          </Typography>
          <p>
            <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
              Limited spots available. Secure your spot today!
            </Typography>
          </p>

          <div className={styles.signupGrid}>
            <div className={styles.signupCard}>
              <div className={styles.signupLocation}>
                <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
                  Dallas, TX
                </Typography>
              </div>
              <div className={styles.signupVenue}>
                <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                  DFW RV Party @ AT&T Stadium
                </Typography>
              </div>
              <div className={styles.signupDates}>
                <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                  March 13, 14, 15, or 16, 2025
                </Typography>
              </div>
              <Button
                variant="filled"
                rightIcon={<Icon name="chevronRight" />}
                onClick={() => {
                  logEvent(analytics, 'select_content', {
                    content_type: 'signup_button',
                    content_id: 'signup_dallas',
                    location: 'dallas',
                  });
                  const selectedModule = sessionStorage.getItem('selectedModule');
                  const selectedBookeoId = sessionStorage.getItem('selectedBookeoId');
                  let url = '/dallas';
                  const params = new URLSearchParams();
                  if (selectedModule) params.append('module', selectedModule);
                  if (selectedBookeoId) params.append('bid', selectedBookeoId);
                  if (params.toString()) url += `?${params.toString()}`;
                  navigate(url);
                  sessionStorage.removeItem('selectedModule');
                  sessionStorage.removeItem('selectedBookeoId');
                }}
              >
                Sign Up
              </Button>
            </div>

            <div className={styles.signupCard}>
              <div className={styles.signupLocation}>
                <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
                  Coming Soon
                </Typography>
              </div>
              <div className={styles.signupVenue}>
                <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                  Location to be announced
                </Typography>
              </div>
              <div className={styles.signupDates}>
                <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                  Summer 2025
                </Typography>
              </div>
              <Button
                variant="filled"
                rightIcon={<Icon name="chevronRight" />}
                onClick={() => {
                  logEvent(analytics, 'select_content', {
                    content_type: 'signup_button',
                    content_id: 'signup_summer',
                    location: 'summer',
                  });
                  document.getElementById('signup')?.scrollIntoView({ behavior: 'smooth' });
                }}
                disabled
              >
                Coming Soon
              </Button>
            </div>

            <div className={styles.signupCard}>
              <div className={styles.signupLocation}>
                <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
                  Coming Soon
                </Typography>
              </div>
              <div className={styles.signupVenue}>
                <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                  Location to be announced
                </Typography>
              </div>
              <div className={styles.signupDates}>
                <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                  Fall 2025
                </Typography>
              </div>
              <Button
                variant="filled"
                rightIcon={<Icon name="chevronRight" />}
                onClick={() => {
                  logEvent(analytics, 'select_content', {
                    content_type: 'signup_button',
                    content_id: 'signup_fall',
                    location: 'fall',
                  });
                  document.getElementById('signup')?.scrollIntoView({ behavior: 'smooth' });
                }}
                disabled
              >
                Coming Soon
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.lightContent}>
        <div className={styles.contentSection}>
          <div className={styles.promoSection}>
            <div className={styles.promoContent}>
              <div className={styles.promoHeading}>
                <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
                  What we hope you'll gain
                </Typography>
              </div>
              <div className={styles.promoText}>
                <ul className={styles.gainsList}>
                  <li>
                    <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                      Enhanced towing skills
                    </Typography>
                  </li>
                  <li>
                    <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                      In-depth understanding of Ford towing capabilities
                    </Typography>
                  </li>
                  <li>
                    <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                      Added towing safety techniques
                    </Typography>
                  </li>
                  <li>
                    <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                      Increased confidence on the road
                    </Typography>
                  </li>
                </ul>
              </div>
              <Button
                variant="outlined"
                rightIcon={<Icon name="chevronRight" />}
                onClick={() => {
                  logEvent(analytics, 'select_content', {
                    content_type: 'signup_button',
                    content_id: 'gains_section',
                  });
                  document.getElementById('signup')?.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Sign Up Now
              </Button>
            </div>
            <div className={styles.promoImage}>
              <img src={team} alt="Group of Ford Towing Bootcamp participants" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.lightContent}>
        <div className={styles.contentSection}>
          <div className={`${styles.promoSection} ${styles.imageRight}`}>
            <div className={styles.promoContent}>
              <div className={styles.promoHeading}>
                <Typography displayStyle="title-medium" displayColor="text-onlight-strong">
                  What our graduates are saying
                </Typography>
              </div>
              <div className={styles.testimonialsList}>
                <div className={styles.testimonial}>
                  <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                    "We're getting to the age where we can retire and get an RV and go do those things and this'll give
                    us the confidence to be able to do that safely."
                  </Typography>
                  <Typography displayStyle="body-2-regular" displayColor="text-onlight-subtle">
                    - Cathy K.
                  </Typography>
                </div>

                <div className={styles.testimonial}>
                  <Typography displayStyle="body-1-regular" displayColor="text-onlight-subtle">
                    "It way exceeded my expectations. It was great, great to be able to try this stuff out and figure
                    out how stuff works and have the people that are experienced to go through it with you so you
                    weren't trying to figure it out on your own."
                  </Typography>
                  <Typography displayStyle="body-2-regular" displayColor="text-onlight-subtle">
                    - Steve B.
                  </Typography>
                </div>
              </div>
            </div>
            <div className={styles.promoImage}>
              <img src={selfHitch} alt="Person using Ford Pro Trailer Hitch Assist" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.darkContent}>
        <div className={styles.contentSection}>
          <Typography displayStyle="display-2-large-regular" displayColor="text-ondark-strong">
            Meet Your Trainers
          </Typography>
          <div className={styles.carousel_container}>
            <ReactSimplyCarousel
              activeSlideIndex={activeSlideIndex}
              onRequestChange={setActiveSlideIndex}
              itemsToShow={window.innerWidth < 768 ? 1 : 4}
              itemsToScroll={1}
              forwardBtnProps={{
                children: (
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.8 12L9.6 4.8L8.76 5.64L15.12 12L8.76 18.36L9.6 19.2L16.8 12Z" fill="white" />
                  </svg>
                ),
                style: {
                  position: 'absolute',
                  right: '-48px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  width: '48px',
                  height: '48px',
                  border: 'none',
                  borderRadius: '50%',
                  background: 'rgba(0, 0, 0, 0.5)',
                  cursor: 'pointer',
                  zIndex: 10,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
              backwardBtnProps={{
                children: (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ transform: 'rotate(180deg)' }}
                  >
                    <path d="M16.8 12L9.6 4.8L8.76 5.64L15.12 12L8.76 18.36L9.6 19.2L16.8 12Z" fill="white" />
                  </svg>
                ),
                style: {
                  position: 'absolute',
                  left: '-48px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  width: '48px',
                  height: '48px',
                  border: 'none',
                  borderRadius: '50%',
                  background: 'rgba(0, 0, 0, 0.5)',
                  cursor: 'pointer',
                  zIndex: 10,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
              hideNavIfAllVisible={false}
              showSlidesBeforeInit
              updateOnItemClick
              infinite
              speed={400}
              responsiveProps={[
                {
                  minWidth: 768,
                  itemsToShow: 4,
                  itemsToScroll: 1,
                },
                {
                  maxWidth: 767,
                  itemsToShow: 1,
                  itemsToScroll: 1,
                },
              ]}
              containerProps={{
                style: {
                  position: 'relative',
                  padding: '0 48px',
                },
              }}
              itemsListProps={{
                style: {
                  gap: '16px',
                },
              }}
            >
              {instructors.map(instructor => (
                <div key={instructor.id} className={styles.instructorCard} data-instructor-id={instructor.id}>
                  <img src={instructor.image} alt={instructor.name} className={styles.instructorImage} />
                  <div className={styles.instructorContent}>
                    <div className={styles.instructorTitle}>
                      <Typography displayStyle="title-medium" displayColor="text-ondark-strong">
                        {instructor.name}
                      </Typography>
                    </div>
                    <div className={styles.buttonWrapper}>
                      <Button
                        variant="outlined"
                        theme="dark"
                        rightIcon={<Icon name="chevronRight" />}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          e.preventDefault();
                          logEvent(analytics, 'select_content', {
                            content_type: 'instructor',
                            content_id: instructor.id,
                          });
                          openInstructorDialog({
                            name: instructor.name,
                            bio: instructor.fullBio,
                          });
                        }}
                      >
                        Learn More
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </ReactSimplyCarousel>
          </div>
        </div>
      </div>

      <dialog
        ref={instructorDialogRef}
        className={styles.modal}
        onClick={e => {
          if (e.target === instructorDialogRef.current) {
            instructorDialogRef.current.close();
          }
        }}
      >
        <button className={styles.modal_close} onClick={() => instructorDialogRef.current?.close()} aria-label="Close">
          <img src={closeIcon} alt="Close" />
        </button>
        <div className={styles.modal_content}>
          {currentInstructor && (
            <>
              <h3 className={styles.modal_title}>{currentInstructor.name}</h3>
              <p className={styles.modal_text}>{currentInstructor.bio}</p>
            </>
          )}
        </div>
      </dialog>
    </>
  );
};

export default Main;
